import React, { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { MdOutlineCancel } from "react-icons/md";
import { useAppContext } from "../../../context/ApplicationContext";
import { FaRegCopy } from "react-icons/fa6";
import toast from "react-hot-toast";

function ApplicationCard({
  _id,
  companyLogo,
  companyName,
  jobTitle,
  searchTag,
}) {
  const [isDelete, setIsDelete] = useState(false);

  const { deleteApplication, getAllApplications } = useAppContext();

  const copyToClipboard = async () => {
    try {
      console.log("In here");
      await navigator.clipboard.writeText(
        `https://franklinosei.com/why/${searchTag}`
      );
      toast.success("Link copied to clipboard", { position: "top-center" });
    } catch (err) {
      console.log(err);
      toast.error("Unable to copy text to clipboard", {
        position: "top-center",
      });
    }
  };

  return (
    <div
      key={_id}
      className="group shadow-xl hover:shadow-primary/10 overflow-hidden transition-transform duration-300 transform rounded-xl"
    >
      <div className="absolute z-10 flex justify-end w-full p-2 gap-x-2 items-center">
        <button className="text-gray-300" onClick={copyToClipboard}>
          <FaRegCopy size={20} />
        </button>

        <div className="flex">
          <button>
            <AiOutlineDelete
              size={20}
              className="text-red-500"
              onClick={() => setIsDelete(true)}
            />
          </button>
          {isDelete ? (
            <div className="flex items-center gap-x-3 bg-red-50/10 rounded-full px-3 shadow-xl">
              <button>
                <MdOutlineCancel
                  size={20}
                  onClick={() => {
                    setIsDelete(false);
                  }}
                  className="text-gray-300"
                />
              </button>
              <button>
                <IoCheckmarkCircleOutline
                  size={20}
                  className="text-green-500"
                  onClick={() => {
                    deleteApplication(_id).then(async () => {
                      await getAllApplications();
                    });
                  }}
                />
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* logo */}
      <div
        className="bg-cover bg-center h-[150px] w-full bg-bgDarker transition-transform duration-300 transform group-hover:scale-105"
        style={{ backgroundImage: `url(${companyLogo})` }}
      ></div>

      {/* body */}
      <div className="p-4 bg-[#1E1E28] border-t-[4px] border-t-primary relative ">
        <div className="flex justify-between items-center">
          <h3 className="text-gray-300 text-lg font-semibold transition-colors duration-300 group-hover:text-primary">
            {companyName}
          </h3>

          <h3 className=" text-xs text-white rounded-xl px-2 w-max bg-blue-800 transition-colors duration-300 group-hover:text-primary">
            {jobTitle}
          </h3>
        </div>
      </div>
    </div>
  );
}

export default ApplicationCard;
