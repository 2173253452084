
import MinimalLayout from "../components/layout/MinimalLayout";
import PageNotFound from "../pages/ErrorPages/PageNotFound";

const ErrorRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/page-not-found",

      //   TODO: change to 404 page
      //   Show a 404 page not found here

      element: <PageNotFound />,
    },
  ],
};

export default ErrorRoutes;
