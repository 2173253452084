import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getGreeting } from "../../utils/getGreetings";
import { useAuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";

const Signin = () => {
  const { isLoading, login, isLoggedIn } = useAuthContext();
  // const navigator = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      // Handle form submission here
      console.log("Form submitted with values:", values);

      login(values).then(() => {
        console.log(isLoggedIn);

        // if (isLoggedIn) {
        //   console.log("User logged in");
        //   navigator("/", { replace: true });
        // }
      });
    },
  });

  return (
    <div className="h-screen flex flex-col items-center justify-center w-full bg-bgDark px-4 md:px-0">
      <div className="text-gray-400 w-full md:w-[40%] border-[1px] border-gray-600 px-6 py-10 rounded shadow-xl shadow-primary/10">
        <div>
          <h3 className="text-xl">Hello, {getGreeting()}!</h3>
          <h5 className="text-sm">Login to continue</h5>
        </div>

        <form className="pt-6 pb-4" onSubmit={formik.handleSubmit}>
          <div className="mb-6">
            <input
              type="email"
              id="email"
              name="email"
              className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                formik.touched.email && formik.errors.email
                  ? "border-red-500"
                  : "border-none"
              }`}
              placeholder="Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500 text-sm mt-2">
                {formik.errors.email}
              </div>
            )}
          </div>
          <div className="mb-6">
            <input
              type="password"
              id="password"
              name="password"
              className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                formik.touched.password && formik.errors.password
                  ? "border-red-500"
                  : "border-none"
              }`}
              placeholder="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="text-red-500 text-sm mt-2">
                {formik.errors.password}
              </div>
            )}
          </div>

          <button
            type="submit"
            className="bg-transparent hover:bg-amber-400 text-sm text-amber-400 py-3 px-4 border border-amber-400 hover:text-[#1E1E28] rounded-lg w-full"
          >
            {isLoading ? (
              <div className="flex items-center justify-center gap-4 ">
                <span>Loging in</span>{" "}
                <BeatLoader size={8} className="pt-[2px] text-bgDarker" />
              </div>
            ) : (
              `Login`
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signin;
