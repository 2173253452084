import { BrowserRouter } from "react-router-dom";
import ApplicationRoutes from "./routes";
import AOS from "aos";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import AnimatedCursor from "react-animated-cursor";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <>
      <AnimatedCursor
        innerSize={10}
        outerSize={40}
        color="255, 193, 7"
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={1.2}
      />
      <Toaster />
      <BrowserRouter>
        <ApplicationRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
