import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { useSkillsContext } from "../../../context/SkillsContext";

function TechCard({ _id, skill, logo }) {
  const { deleteSkillById, getAllSkills } = useSkillsContext();

  return (
    <div className="h-[80px] w-15 relative overflow-hidden border border-primary/20 rounded-2xl shadow-primary/10 shadow-2xl hover:shadow-primary/50">
      {logo && (
        <img
          className="absolute inset-0 w-full h-full object-cover"
          src={`${logo}`}
          alt={skill}
        />
      )}
      <div className="flex justify-end w-full absolute z-10">
        <button
          className="p-2"
          onClick={async () => {
            await deleteSkillById(_id);
            await getAllSkills();
          }}
        >
          <AiOutlineDelete className="text-red-500" />
        </button>
      </div>

      <div className="absolute inset-0 flex items-center justify-center">
        <div className="bg-bgDarker bg-opacity-80 text-white text-center flex items-center justify-center w-full h-full font-bold">
          {skill}
        </div>
      </div>
    </div>
  );
}

export default TechCard;
