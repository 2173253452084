import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import SectionTitle from "../../components/SectionTitle";
import { useUserContext } from "../../context/UserProfileContext";
import { BeatLoader } from "react-spinners";

const ProfilePage = () => {
  const { userProfile, updateProfile, isLoading } = useUserContext();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: userProfile?.username || "",
      firstName: userProfile?.firstName || "",
      lastName: userProfile?.lastName || "",
      linkedIn: userProfile?.linkedIn || "",
      github: userProfile?.github || "",
      twitter: userProfile?.twitter || "",
      profile: userProfile?.profile || "",
      location: userProfile?.location || "",
      language: userProfile?.language || "",
      role: userProfile?.role || "",
      headline: userProfile?.headline || "",
      profession: userProfile?.profession || "",
      profilePicture: userProfile?.profilePicture || "", // New field for the profile picture file
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Username is required"),
      firstName: Yup.string(),
      lastName: Yup.string(),
      linkedIn: Yup.string(),
      github: Yup.string(),
      twitter: Yup.string(),
      profile: Yup.string(),
      location: Yup.string(),
      language: Yup.string(),
      role: Yup.string(),
      headline: Yup.string(),
      profession: Yup.string(),
      profilePicture: Yup.mixed(), // Validation for the profile picture file
    }),
    onSubmit: (values) => {
      const formData = new FormData();

      // Append each field to FormData
      formData.append("username", values.username);
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("linkedIn", values.linkedIn);
      formData.append("github", values.github);
      formData.append("twitter", values.twitter);
      formData.append("profile", values.profile);
      formData.append("location", values.location);
      formData.append("language", values.language);
      formData.append("role", values.role);
      formData.append("headline", values.headline);
      formData.append("profession", values.profession);

      // Append profile picture if selected
      if (values.profilePicture) {
        formData.append("profilePicture", values.profilePicture);
      }

      // Call the updateuserProfileProfile function from your context
      updateProfile(formData).then(() => {
        console.log("Profile Updated");
      });
    },
  });

  return (
    <div className="px-8 py-12 md:p-12">
      <SectionTitle pageName={"Profile"} title={"Edit Profile"} />

      <div className="flex flex-col items-center justify-center w-full bg-bgDark py-6">
        <div className="text-gray-400 w-full border-[1px] border-gray-600 px-6 py-10 rounded shadow-xl shadow-primary/10">
          <form className="pb-4" onSubmit={formik.handleSubmit}>
            {/* Display a preview of the selected profile picture */}
            {
              <img
                src={
                  formik.values.profilePicture
                    ? URL.createObjectURL(formik.values.profilePicture)
                    : userProfile?.profilePicture
                }
                alt="Profile Preview"
                className="mx-auto my-4 rounded-full w-52 h-52 object-center object-cover border border-gray-500"
              />
            }

            <div className="grid grid-cols-1 md:grid-cols-2  gap-x-4">
              {/* Add a new input field for the new profile picture */}
              <div className="mb-3 w-full">
                <label
                  htmlFor="profilePicture"
                  className="block text-sm font-medium text-gray-500"
                >
                  Profile Picture
                </label>
                <input
                  type="file"
                  id="profilePicture"
                  name="profilePicture"
                  accept="image/*"
                  onChange={(event) =>
                    formik.setFieldValue(
                      "profilePicture",
                      event.currentTarget.files[0]
                    )
                  }
                  className={`text-sm w-full p-3 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 ${
                    formik.touched.profilePicture &&
                    formik.errors.profilePicture
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.profilePicture &&
                  formik.errors.profilePicture && (
                    <div className="text-red-500 text-sm mt-2">
                      {formik.errors.profilePicture}
                    </div>
                  )}
              </div>

              {/* Your input fields for the user profile data go here */}
              <div className="mb-3 w-full">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-500"
                >
                  Username
                </label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 ${
                    formik.touched.username && formik.errors.username
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.username && formik.errors.username && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.username}
                  </div>
                )}
              </div>

              {/* first name */}
              <div className="mb-3 w-full">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-500"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 ${
                    formik.touched.firstName && formik.errors.firstName
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.firstName}
                  </div>
                )}
              </div>

              {/* last name */}

              <div className="mb-3 w-full">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-500"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 ${
                    formik.touched.lastName && formik.errors.lastName
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.lastName}
                  </div>
                )}
              </div>

              <div className="mb-3 w-full">
                <label
                  htmlFor="linkedIn"
                  className="block text-sm font-medium text-gray-500"
                >
                  LinkedIn Url
                </label>
                <input
                  type="text"
                  id="linkedIn"
                  name="linkedIn"
                  value={formik.values.linkedIn}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 ${
                    formik.touched.linkedIn && formik.errors.linkedIn
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.linkedIn && formik.errors.linkedIn && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.linkedIn}
                  </div>
                )}
              </div>

              <div className="mb-3 w-full">
                <label
                  htmlFor="github"
                  className="block text-sm font-medium text-gray-500"
                >
                  Github Url
                </label>
                <input
                  type="text"
                  id="github"
                  name="github"
                  value={formik.values.github}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 ${
                    formik.touched.github && formik.errors.github
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.github && formik.errors.github && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.github}
                  </div>
                )}
              </div>

              <div className="mb-3 w-full">
                <label
                  htmlFor="twitter"
                  className="block text-sm font-medium text-gray-500"
                >
                  Twitter Url
                </label>
                <input
                  type="text"
                  id="twitter"
                  name="twitter"
                  value={formik.values.twitter}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 ${
                    formik.touched.twitter && formik.errors.twitter
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.twitter && formik.errors.twitter && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.twitter}
                  </div>
                )}
              </div>

              <div className="mb-3 w-full">
                <label
                  htmlFor="location"
                  className="block text-sm font-medium text-gray-500"
                >
                  Current Location
                </label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={formik.values.location}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 ${
                    formik.touched.location && formik.errors.location
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.location && formik.errors.location && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.location}
                  </div>
                )}
              </div>

              <div className="mb-3 w-full">
                <label
                  htmlFor="language"
                  className="block text-sm font-medium text-gray-500"
                >
                  Native Language
                </label>
                <input
                  type="text"
                  id="language"
                  name="language"
                  value={formik.values.language}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 ${
                    formik.touched.language && formik.errors.language
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.language && formik.errors.language && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.language}
                  </div>
                )}
              </div>

              <div className="mb-3 w-full">
                <label
                  htmlFor="profession"
                  className="block text-sm font-medium text-gray-500"
                >
                  Current Profession
                </label>
                <input
                  type="text"
                  id="profession"
                  name="profession"
                  value={formik.values.profession}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 ${
                    formik.touched.profession && formik.errors.profession
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.profession && formik.errors.profession && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.profession}
                  </div>
                )}
              </div>
            </div>

            {/* profile headline */}
            <div className="mb-3 w-full">
              <label
                htmlFor="headline"
                className="block text-sm font-medium text-gray-500"
              >
                Profile Headline
              </label>
              <textarea
                rows={3}
                type="text"
                id="headline"
                name="headline"
                value={formik.values.headline}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 ${
                  formik.touched.headline && formik.errors.headline
                    ? "border-red-500"
                    : ""
                }`}
              />
              {formik.touched.headline && formik.errors.headline && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors.headline}
                </div>
              )}
            </div>

            {/* profile statement */}
            <div className="mb-3 w-full">
              <label
                htmlFor="profile"
                className="block text-sm font-medium text-gray-500"
              >
                Profile Statement
              </label>
              <textarea
                rows={6}
                type="text"
                id="profile"
                name="profile"
                value={formik.values.profile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 ${
                  formik.touched.profile && formik.errors.profile
                    ? "border-red-500"
                    : ""
                }`}
              />
              {formik.touched.profile && formik.errors.profile && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors.profile}
                </div>
              )}
            </div>

            {/* Repeat similar blocks for other profile fields */}
            {/* ... */}

            {/* Submit button */}
            <div className="flex justify-center items-center mt-6">
              <button
                type="submit"
                disabled={isLoading}
                className="bg-transparent hover:bg-amber-400 text-sm text-amber-400 py-3 px-24 border border-amber-400 hover:text-[#1E1E28] rounded-lg"
              >
                {isLoading ? (
                  <div className="flex items-center justify-center gap-4 ">
                    <span>Submitting</span>
                    <BeatLoader size={8} className="pt-[2px] text-bgDarker" />
                  </div>
                ) : (
                  `Submit`
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
