import React, { useState } from "react";
import SectionTitle from "../../components/SectionTitle";
import { useAppContext } from "../../context/ApplicationContext";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { BeatLoader } from "react-spinners";
import * as Yup from "yup";
import { CiCircleCheck } from "react-icons/ci";
import { AiOutlineDelete } from "react-icons/ai";

function AddNewApplication() {
  const { isLoading, createApplication } = useAppContext();
  const navigate = useNavigate();

  const [isAddResp, setIsAddResp] = useState(false);

  const formik = useFormik({
    initialValues: {
      companyName: "",
      jobTitle: "",
      companyLogo: "",
      headline: "",
      job: "",
      mine: "",
      searchTag: "",
      responsibilities: [],
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required("Required"),
      jobTitle: Yup.string().required("Required"),
      companyLogo: Yup.string().required("Required"),
      searchTag: Yup.string().required("Required"),
      headline: Yup.string().required("Required"),
      // Add validation for other fields as needed
    }),
    onSubmit: async (values) => {
      const { job, mine, ...jsonData } = values;
      await createApplication(jsonData);
      formik.resetForm();
      navigate("/applications");
    },
  });

  return (
    <div className="p-4 md:p-12">
      <SectionTitle
        pageName={"Job Applications"}
        title={"Add new job application"}
      />

      <main className="mt-8">
        <form className="pb-4 text-gray-300" onSubmit={formik.handleSubmit}>
          {/* ... (existing form fields) */}
          <div className="flex flex-col md:flex-row w-full md:gap-x-6">
            <div className="mb-6 w-full">
              <label
                htmlFor="companyName"
                className="block text-sm font-medium text-gray-500"
              >
                Company Name
              </label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                  formik.touched.companyName && formik.errors.companyName
                    ? "border-red-500"
                    : "border-none"
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyName}
              />
              {formik.touched.companyName && formik.errors.companyName && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors.companyName}
                </div>
              )}
            </div>

            <div className="mb-6 w-full">
              <label
                htmlFor="companyLogo"
                className="block text-sm font-medium text-gray-500"
              >
                Company Logo
              </label>
              <input
                type="text"
                id="companyLogo"
                name="companyLogo"
                className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                  formik.touched.companyLogo && formik.errors.companyLogo
                    ? "border-red-500"
                    : "border-none"
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyLogo}
              />
              {formik.touched.companyLogo && formik.errors.companyLogo && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors.companyLogo}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full md:gap-x-6">
            <div className="mb-6 w-full">
              <label
                htmlFor="jobTitle"
                className="block text-sm font-medium text-gray-500"
              >
                Job Title
              </label>
              <input
                type="text"
                id="jobTitle"
                name="jobTitle"
                className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                  formik.touched.jobTitle && formik.errors.jobTitle
                    ? "border-red-500"
                    : "border-none"
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.jobTitle}
              />
              {formik.touched.jobTitle && formik.errors.jobTitle && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors.jobTitle}
                </div>
              )}
            </div>

            <div className="mb-6 w-full">
              <label
                htmlFor="searchTag"
                className="block text-sm font-medium text-gray-500"
              >
                Search Tag
              </label>
              <input
                type="text"
                id="searchTag"
                name="searchTag"
                className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                  formik.touched.searchTag && formik.errors.searchTag
                    ? "border-red-500"
                    : "border-none"
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.searchTag}
              />
              {formik.touched.searchTag && formik.errors.searchTag && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors.searchTag}
                </div>
              )}
            </div>
          </div>

          <div className="mb-6 w-full">
            <label
              htmlFor="headline"
              className="block text-sm font-medium text-gray-500"
            >
              Headline
            </label>
            <textarea
              type="text"
              rows={5}
              id="headline"
              name="headline"
              className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                formik.touched.headline && formik.errors.headline
                  ? "border-red-500"
                  : "border-none"
              }`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.headline}
            />
            {formik.touched.headline && formik.errors.headline && (
              <div className="text-red-500 text-sm mt-2">
                {formik.errors.headline}
              </div>
            )}
          </div>

          <div className="mb-6 w-full">
            <div className="flex gap-6">
              <label
                htmlFor="responsibilities"
                className="block text-sm font-medium text-gray-500"
              >
                Responsibilities
              </label>

              <button
                className="text-primary text-sm"
                onClick={() => setIsAddResp(true)}
              >
                Add
              </button>
            </div>

            <div className="my-2 w-full">
              {formik.values.responsibilities.map((data, index) => {
                return (
                  <div
                    className="flex gap-6 mt-2 text-gray-300 w-full text-sm"
                    key={index}
                  >
                    <p className="w-full">{data.job}</p>
                    <p className="w-full">{data.mine.join(", ")}</p>
                    <button>
                      <AiOutlineDelete
                        className="text-red-600"
                        size={20}
                        onClick={() => {
                          formik.setFieldValue(
                            "responsibilities",
                            formik.values.responsibilities.filter(
                              (val, idx) => idx !== index
                            )
                          );
                        }}
                      />
                    </button>
                  </div>
                );
              })}
            </div>

            {isAddResp && (
              <div className="w-full flex gap-6 items-center">
                <div className="w-full">
                  <label
                    htmlFor="job"
                    className="block text-sm font-medium text-gray-500"
                  >
                    Job
                  </label>
                  <textarea
                    type="text"
                    rows={1}
                    id="job"
                    name="job"
                    className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                      formik.touched.job && formik.errors.job
                        ? "border-red-500"
                        : "border-none"
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.job}
                  />
                </div>

                <div className="w-full">
                  <label
                    htmlFor="mine"
                    className="block text-sm font-medium text-gray-500"
                  >
                    Mine
                  </label>
                  <textarea
                    type="text"
                    rows={1}
                    id="mine"
                    name="mine"
                    className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                      formik.touched.mine && formik.errors.mine
                        ? "border-red-500"
                        : "border-none"
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mine}
                  />
                </div>

                <div>
                  <button
                    className="text-green-600"
                    onClick={() => {
                      setIsAddResp(false);
                      formik.setFieldValue("responsibilities", [
                        ...formik.values.responsibilities,
                        {
                          job: formik.values.job,
                          mine: formik.values.mine.split("\n"),
                        },
                      ]);
                      formik.setFieldValue("job", "");
                      formik.setFieldValue("mine", "");
                    }}
                  >
                    <CiCircleCheck size={30} />
                  </button>
                </div>
              </div>
            )}

            {formik.touched.responsibilities &&
              formik.errors.responsibilities && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors.responsibilities}
                </div>
              )}
          </div>

          <div className="flex justify-center items-center">
            <button
              type="submit"
              className={`bg-transparent hover:bg-amber-400 text-sm text-amber-400 py-3 px-24 border border-amber-400 hover:text-[#1E1E28] rounded-lg ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="flex items-center justify-center gap-4 ">
                  <span>Submitting</span>
                  <BeatLoader size={8} className="pt-[2px] text-bgDarker" />
                </div>
              ) : (
                `Submit`
              )}
            </button>
          </div>
        </form>
      </main>
    </div>
  );
}

export default AddNewApplication;
