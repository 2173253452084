import React, { createContext, useContext, useEffect, useState } from "react";
import { apiConnect } from "../api/axios";
import toast from "react-hot-toast";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function handleError(error) {
    const { response } = error;
    toast.error(
      response?.data?.message ?? "An error occurred, please try again!",
      { position: "top-center" }
    );
  }

  const getProfile = async () => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getProfile();

      if (response.status === 200) {
        setUserProfile(response.data);
      } else {
        toast.error("Failed to fetch user profile. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateProfile = async (updatedProfile) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.updateProfile(updatedProfile);

      if (response.status === 200) {
        setUserProfile(response.data);
        toast.success("Profile updated successfully!");
      } else {
        toast.error("Failed to update profile. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  //   const changePassword = async (newPassword) => {
  //     try {
  //       setIsLoading(true);
  //       const response = await apiConnect.changePassword(newPassword);

  //       if (response.status === 200) {
  //         toast.success("Password changed successfully!");
  //       } else {
  //         toast.error("Failed to change password. Please try again later.");
  //       }
  //     } catch (error) {
  //       handleError(error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <UserContext.Provider
      value={{
        userProfile,
        isLoading,
        getProfile,
        updateProfile,
        // changePassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
