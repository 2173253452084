import axios from "axios";

class Api {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });

    this.api.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("authToken");
        if (token) {
          config.headers = {
            Authorization: `Bearer ${token}`,
          };
        }
        return config;
      },
      (error) => {
        console.log(error);
        throw error;
      }
    );
  }

  // ============ Admin Auth Routes ===============

  login(loginData) {
    return this.api.post("/auth/login", loginData);
  }

  verify() {
    return this.api.get("/auth/verify");
  }

  getProfile() {
    return this.api.get("/me");
  }

  updateProfile(profileData) {
    return this.api.put(`/me`, profileData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  // ================================================================

  // =================== Admin Blogs Routes ===================

  getAllBlogs() {
    return this.api.get("/blog");
  }

  getBlogById(id) {
    return this.api.get(`/blog/${id}`);
  }

  updateBlogById(id, blogData) {
    return this.api.put(`/blog/${id}`, blogData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  deleteBlogById(id) {
    return this.api.delete(`/blog/${id}`);
  }

  createBlog(blogData) {
    return this.api.post(`/blog`, blogData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  publishBlog(id) {
    return this.api.patch(`/blog/${id}/publish`);
  }

  makeBlogDraft(id) {
    return this.api.patch(`/blog/${id}/make-draft`);
  }

  publishBlogToExternalSites(id) {
    return this.api.patch(`/blog/${id}/publish-externally`);
  }

  // ==========================================================

  // ============== Education Routes =============================

  createEducation(educationData) {
    return this.api.post("/education", educationData);
  }

  getAllEducation() {
    return this.api.get("/education");
  }

  getEducationById(id) {
    return this.api.get(`/education/${id}`);
  }

  updateEducationById(id, updateData) {
    return this.api.put(`/education/${id}`, updateData);
  }

  deleteEducationById(id) {
    return this.api.delete(`/education/${id}`);
  }

  // =========================================================

  // ================ Projects Routes ========================

  createProjects(projectsData) {
    return this.api.post("/projects", projectsData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  getAllProjects() {
    return this.api.get("/projects");
  }

  getProjectsById(id) {
    return this.api.get(`/projects/${id}`);
  }

  updateProjectsById(id, updateData) {
    return this.api.put(`/projects/${id}`, updateData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  deleteProjectsById(id) {
    return this.api.delete(`/projects/${id}`);
  }

  // =======================================================

  // ================ Resume/Work Experience routes =========================
  createResume(resumeData) {
    return this.api.post("/work-experience", resumeData);
  }

  getAllResume() {
    return this.api.get("/work-experience");
  }

  getResumeById(id) {
    return this.api.get(`/work-experience/${id}`);
  }

  updateResumeById(id, updateData) {
    return this.api.put(`/work-experience/${id}`, updateData);
  }

  deleteResumeById(id) {
    return this.api.delete(`/work-experience/${id}`);
  }

  // ========================================================

  // ================ Skills Routes =========================
  createSkill(skillData) {
    return this.api.post("/skills", skillData);
  }

  getAllSkills() {
    return this.api.get("/skills");
  }

  getSkillById(id) {
    return this.api.get(`/skills/${id}`);
  }

  updateSkillById(id, updateData) {
    return this.api.put(`/skills/${id}`, updateData);
  }

  deleteSkillById(id) {
    return this.api.delete(`/skills/${id}`);
  }
  // ========================================================

  // ================= Job Application Routes ==================

  createApplication(jobApplicationData) {
    return this.api.post("/applications", jobApplicationData);
  }

  getAllSentApplications() {
    return this.api.get("/applications");
  }

  getJobApplicationByTag(tag) {
    return this.api.get(`/applications/${tag}`);
  }

  deleteApplicationById(id) {
    return this.api.delete(`/applications/${id}`);
  }
  // ============================================================
}

export const apiConnect = new Api();
