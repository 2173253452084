import React, { useEffect, useRef, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { useBlogContext } from "../../context/BlogContext";
import MDEditor from "@uiw/react-md-editor";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineCancel, MdOutlinePublish } from "react-icons/md";
import { RiDraftLine } from "react-icons/ri";
import { AiOutlineDelete } from "react-icons/ai";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

function BlogReadPage() {
  const {
    getBlogById,
    blogDetails,
    isLoading,
    deleteBlogById,
    publishBlogToExternalSites,
  } = useBlogContext();
  const navigator = useNavigate();
  const { blogId } = useParams();

  const [isDelete, setIsDelete] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  console.log(blogDetails);

  useEffect(() => {
    getBlogById(blogId);
  }, [blogId]);

  return (
    <div className="px-8 py-24 md:p-12 h-full overflow-hidden">
      <div className="flex flex-col md:flex-row md:justify-between">
        <button
          className="flex gap-3 text-bgDarker items-center w-max px-4 py-1 bg-primary"
          onClick={() => navigator("/blog")}
        >
          <IoMdArrowBack size={20} className="" />
          <p>All Blogs</p>
        </button>

        <div className="flex gap-x-3">
          {!blogDetails?.isDraft && (
            <button
              className="flex gap-3 items-center w-max px-4 py-1 text-primary border border-primary hover:bg-primary hover:text-bgDarker"
              onClick={() => setIsDraft(true)}
            >
              <RiDraftLine size={20} className="" />
              <p>Make Draft</p>
            </button>
          )}

          {!blogDetails?.isDraft && isDraft && (
            <div className="flex items-center gap-x-3 bg-red-50/10 rounded-full px-3 shadow-xl">
              <button>
                <MdOutlineCancel
                  size={20}
                  onClick={() => {
                    setIsDraft(false);
                  }}
                  className="text-gray-300"
                />
              </button>
              <button>
                <IoCheckmarkCircleOutline
                  size={20}
                  className="text-green-500"
                  onClick={() => {
                    // Publish externally
                    // deleteBlogById(blogId).then(async () => {
                    //   // await getAllBlogs();
                    //   navigator("/blog");
                    // });
                  }}
                />
              </button>
            </div>
          )}

          {blogDetails?.isDraft && (
            <button
              className="flex gap-3 items-center w-max px-4 py-1 text-primary border border-primary hover:bg-primary hover:text-bgDarker"
              onClick={() => setIsPublish(true)}
            >
              <MdOutlinePublish size={20} className="" />
              <p>Publish External</p>
            </button>
          )}

          {blogDetails?.isDraft && isPublish && (
            <div className="flex items-center gap-x-3 bg-red-50/10 rounded-full px-3 shadow-xl">
              <button>
                <MdOutlineCancel
                  size={20}
                  onClick={() => {
                    setIsPublish(false);
                  }}
                  className="text-gray-300"
                />
              </button>
              <button>
                <IoCheckmarkCircleOutline
                  size={20}
                  className="text-green-500"
                  onClick={() => {
                    // Publish externally
                    publishBlogToExternalSites(blogId).then(async () => {
                      await getBlogById(blogId);
                      
                    });
                  }}
                />
              </button>
            </div>
          )}

          <button
            className="flex gap-3 items-center w-max px-4 py-1 text-primary border border-primary hover:bg-primary hover:text-bgDarker"
            onClick={() => navigator(`/blog/${blogId}/update`)}
          >
            <FaRegEdit size={20} className="" />
            <p>Edit</p>
          </button>
          <button
            className="flex gap-3 items-center w-max px-4 py-1 text-red-500 border border-red-500 hover:bg-primary hover:text-bgDarker"
            onClick={() => setIsDelete(true)}
          >
            <AiOutlineDelete size={20} className="text-red-500" />
            <p>Delete</p>
          </button>

          {isDelete && (
            <div className="flex items-center gap-x-3 bg-red-50/10 rounded-full px-3 shadow-xl">
              <button>
                <MdOutlineCancel
                  size={20}
                  onClick={() => {
                    setIsDelete(false);
                  }}
                  className="text-gray-300"
                />
              </button>
              <button>
                <IoCheckmarkCircleOutline
                  size={20}
                  className="text-green-500"
                  onClick={() => {
                    deleteBlogById(blogId).then(async () => {
                      // await getAllBlogs();
                      navigator("/blog");
                    });
                  }}
                />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="mt-12 w-full h-full text-gray-300">
        <MDEditor.Markdown
          source={blogDetails?.blogContent}
          style={{ backgroundColor: "transparent" }}
        />
      </div>
    </div>
  );
}

export default BlogReadPage;
