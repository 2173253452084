import { Navigate } from "react-router-dom";
import MainLayout from "../components/layout/MainLayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import Education from "../pages/Education/EducationPage";
import AddEducation from "../pages/Education/AddEducationPage";
import UpdateEducationPage from "../pages/Education/UpdateEducation";
import Resume from "../pages/Resume/ResumePage";
import AddResume from "../pages/Resume/AddResumePage";
import UpdateResumePage from "../pages/Resume/UpdateResume";
import ProjectsPage from "../pages/Projects/ProjectsPage";
import AddProjectPage from "../pages/Projects/AddProjectPage";
import UpdateProjectPage from "../pages/Projects/UpdateProjectPage";
import ProfilePage from "../pages/Settings/UserProfile";
import SkillsPage from "../pages/Skills/SkillsPage";
import AddSkillsPage from "../pages/Skills/AddSkillsPage";
import AllBlogPage from "../pages/Blog/AllBlogsPage";
import BlogReadPage from "../pages/Blog/BlogReadPage";
import WriteBlog from "../pages/Blog/WriteBlog";
import UpdateBlog from "../pages/Blog/UpdateBlog";
import ApplicationsPage from "../pages/Applications/Applications";
import AddNewApplication from "../pages/Applications/AddNewApplication";

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    //   ================= dashboard =================

    {
      path: "/",
      element: <Navigate to="/dashboard" />,
    },
    {
      path: "dashboard",
      element: <Dashboard />,
    },

    //  ==================== End Dashboard =========================

    // ================= other routes goes here ===================

    {
      path: "settings",
      element: <ProfilePage />,
    },

    {
      path: "/skills",
      children: [
        {
          path: "",
          element: <SkillsPage />,
        },

        {
          path: "add",
          element: <AddSkillsPage />,
        },
      ],
    },

    {
      path: "/applications",
      children: [
        {
          path: "",
          element: <ApplicationsPage />,
        },

        {
          path: "add",
          element: <AddNewApplication />,
        },
      ],
    },

    {
      path: "/blog",
      children: [
        {
          path: "",
          element: <AllBlogPage />,
        },

        {
          path: "write",
          element: <WriteBlog />,
        },

        {
          path: ":blogId/update",
          element: <UpdateBlog />,
        },

        {
          path: ":blogId/preview",
          element: <BlogReadPage />,
        },
      ],
    },

    {
      path: "/projects",
      children: [
        {
          path: "",
          element: <ProjectsPage />,
        },

        {
          path: ":projectID",
          element: <h1>Project Details Page</h1>,
        },

        {
          path: ":projectID/edit",
          element: <UpdateProjectPage />,
        },

        {
          path: "add",
          element: <AddProjectPage />,
        },
      ],
    },

    {
      path: "/education",
      children: [
        {
          path: "",
          element: <Education />,
        },

        {
          path: ":educationID/edit",
          element: <UpdateEducationPage />,
        },

        {
          path: "add",
          element: <AddEducation />,
        },
      ],
    },

    {
      path: "/resume",
      children: [
        {
          path: "",
          element: <Resume />,
        },

        {
          path: ":resumeID/edit",
          element: <UpdateResumePage />,
        },
        {
          path: "add",
          element: <AddResume />,
        },
      ],
    },

    //  ======================== End Other routes =================

    // NB: Keep this as the last route
    // ========== 404 pages ====================

    {
      path: "*",
      element: <Navigate to="/" />,
    },

    //  =======================
  ],
};

export default MainRoutes;
