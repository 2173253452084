import { createContext, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { apiConnect } from "../api/axios";

const AuthContext = createContext({});

function AuthProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isSettingUp, setIsSettingUp] = useState(true);

  // stores token to local storage
  const storeToken = (token) => {
    localStorage.setItem("authToken", token);
  };

  // A global error handler
  function handleError(error) {
    const { response } = error;
    toast.error(
      response?.data?.message ?? "Couldn't log you in, please try again!",
      { position: "top-center" }
    );
    setIsLoading(false);
  }

  // const authenticate = () => {
  //   //  <==  ADD
  //   // Get the stored token from the localStorage
  //   const storedToken = localStorage.getItem("authToken");

  //   // If the token exists in the localStorage
  //   if (storedToken) {
  //     // We must send the JWT token in the request's "Authorization" Headers

  //     apiConnect
  //       .verify()
  //       .then((response) => {
  //         // Check if response and response.data are defined
  //         if (response && response.data) {
  //           // If the server verifies that the JWT token is valid
  //           const user = response.data;
  //           // Update state variables
  //           setIsLoggedIn(true);
  //           setCurrentUser(user);
  //         } else {
  //           setIsLoggedIn(false);
  //           throw new Error("Invalid response from the server");
  //         }
  //       })
  //       .catch((error) => {
  //         const { response } = error;

  //         // log response to see what's wrong
  //         // console.log(response);

  //         setIsLoggedIn(false);
  //         setCurrentUser(null);
  //       });
  //   } else {
  //     // If the token is not available (or is removed)
  //     setIsLoggedIn(false);
  //     setCurrentUser(null);
  //   }

  //   setIsSettingUp(false);
  // };

  const authenticate = async () => {
    const cachedToken = localStorage.getItem("authToken");

    if (cachedToken) {
      try {
        const response = await apiConnect.verify();

        if (response && response.status === 200) {
          setCurrentUser(response.data);
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
          setCurrentUser(null);
        }
      } catch (error) {
        console.log(error);
        // TODO: check status code and implement token refresh logic
        setIsLoggedIn(false);
        setCurrentUser(null);
      } finally {
        setIsSettingUp(false);
      }

      // No cached token, user logs in again
    } else {
      setIsLoggedIn(false);
      setCurrentUser(null);
      // setIsSettingUp(false);
    }
  };

  // handles user logins
  async function login(loginData) {
    try {
      setIsLoading(true);

      const response = await apiConnect.login(loginData);

      if (response.status === 200) {
        storeToken(response.data.authToken);

        // authenitcate user now
        await authenticate();
      } else {
        handleError(
          new Error(
            "Sorry! There is a technical challenge on our side, we couldn't log you in. Please try again"
          )
        );
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }

  function logOutUser() {
    localStorage.removeItem("authToken");
    authenticate();
  }

  // authenticate user
  useEffect(() => {
    authenticate();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isLoggedIn,
        currentUser,
        isSettingUp,
        login,
        logOutUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// Define a custom hook to access the context
const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }

  return context;
};

export { AuthProvider, AuthContext, useAuthContext };
