import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle";
import { useSkillsContext } from "../../context/SkillsContext";
import { BeatLoader } from "react-spinners";

const AddSkillPage = () => {
  const { isLoading, createSkill } = useSkillsContext();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      skill: "",
      proficiency: "",
      proficiencyPercentage: 0,
      description: "",
      logo: "",
    },
    validationSchema: Yup.object({
      skill: Yup.string().required("Required"),
      proficiency: Yup.string().required("Required"),
      proficiencyPercentage: Yup.number().min(0).max(100),
      description: Yup.string(),
      logo: Yup.string(),
    }),
    onSubmit: (values) => {
      createSkill(values).then(() => {
        formik.resetForm();
        navigate("/skills");
      });

      // Assuming you have a function to navigate back to the skills list page
    },
  });

  return (
    <div className="p-4 md:p-12">
      <SectionTitle pageName={"Skills"} title={"Add new skill record"} />

      <div className="flex flex-col items-center justify-center w-full bg-bgDark py-6">
        <div className="text-gray-400 w-full border-[1px] border-gray-600 px-6 py-10 rounded shadow-xl shadow-primary/10">
          <form className="pb-4" onSubmit={formik.handleSubmit}>
            <div className="flex flex-col md:flex-row w-full md:gap-x-6">
              <div className="mb-6 w-full">
                <label
                  htmlFor="skill"
                  className="block text-sm font-medium text-gray-500"
                >
                  Skill
                </label>
                <input
                  type="text"
                  id="skill"
                  name="skill"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.skill && formik.errors.skill
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.skill}
                />
                {formik.touched.skill && formik.errors.skill && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.skill}
                  </div>
                )}
              </div>

              <div className="mb-6 w-full">
                <label
                  htmlFor="proficiency"
                  className="block text-sm font-medium text-gray-500"
                >
                  Proficiency
                </label>
                <input
                  type="text"
                  id="proficiency"
                  name="proficiency"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.proficiency && formik.errors.proficiency
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.proficiency}
                />
                {formik.touched.proficiency && formik.errors.proficiency && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.proficiency}
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row w-full md:gap-x-6">
              {/* Add other fields as needed */}

              <div className="mb-6 w-full">
                <label
                  htmlFor="proficiencyPercentage"
                  className="block text-sm font-medium text-gray-500"
                >
                  Proficiency Percentage
                </label>
                <input
                  type="number"
                  id="proficiencyPercentage"
                  name="proficiencyPercentage"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.proficiencyPercentage &&
                    formik.errors.proficiencyPercentage
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.proficiencyPercentage}
                />
                {formik.touched.proficiencyPercentage &&
                  formik.errors.proficiencyPercentage && (
                    <div className="text-red-500 text-sm mt-2">
                      {formik.errors.proficiencyPercentage}
                    </div>
                  )}
              </div>
            </div>

            {/* Add other fields as needed */}
            
            <div className="mb-6">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-500"
              >
                Description
              </label>
              <textarea
                id="description"
                name="description"
                rows="4"
                className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                  formik.touched.description && formik.errors.description
                    ? "border-red-500"
                    : "border-none"
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
              {formik.touched.description && formik.errors.description && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors.description}
                </div>
              )}
            </div>

            <div className="mb-6">
              <label
                htmlFor="logo"
                className="block text-sm font-medium text-gray-500"
              >
                Logo
              </label>
              <input
                type="text"
                id="logo"
                name="logo"
                className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                  formik.touched.logo && formik.errors.logo
                    ? "border-red-500"
                    : "border-none"
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.logo}
              />
              {formik.touched.logo && formik.errors.logo && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors.logo}
                </div>
              )}
            </div>

            <div className="flex justify-center items-center">
              <button
                type="submit"
                className="bg-transparent hover:bg-amber-400 text-sm text-amber-400 py-3 px-24 border border-amber-400 hover:text-[#1E1E28] rounded-lg"
              >
                {isLoading ? (
                  <div className="flex items-center justify-center gap-4 ">
                    <span>Submitting</span>
                    <BeatLoader size={8} className="pt-[2px] text-bgDarker" />
                  </div>
                ) : (
                  `Submit`
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddSkillPage;
