import React, { useEffect } from "react";
import SectionTitle from "../../components/SectionTitle";
import { useResumeContext } from "../../context/ResumeContext";
import ResumeCard from "./components/ResumeCard";
import { FaFileAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Resume = () => {
  const { getAllResumes, allResumes } = useResumeContext();

  const navigator = useNavigate();

  useEffect(() => {
    getAllResumes();
  }, []);

  return (
    <div className="p-12">
      <div className="flex justify-between">
        <SectionTitle pageName={"Resume"} title={"All Resumes"} />

        <button
          className="flex items-center gap-x-2 text-primary border border-primary rounded-xl px-2 my-[10px] hover:text-bgDarker hover:bg-primary hover:shadow-xl hover:shadow-primary/10"
          onClick={() => {
            navigator("/resume/add");
          }}
        >
          <span>
            <FaFileAlt size={25} />
          </span>
          <h6>Add Resume</h6>
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-6 mt-12">
        {allResumes.map((val, i) => (
          <ResumeCard key={i} {...val} />
        ))}
      </div>
    </div>
  );
};

export default Resume;
