import { useEffect, useState } from "react";
import { useRef } from "react";
// import SubMenu from "./SubMenu";
import { motion } from "framer-motion";
import logo from "../../assets/logo.png";

// * React icons
import { SlSettings } from "react-icons/sl";
import { AiOutlineAppstore } from "react-icons/ai";
import { useMediaQuery } from "react-responsive";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { IoIosLogOut } from "react-icons/io";
import { RiToolsLine } from "react-icons/ri";

import { RiGraduationCapFill } from "react-icons/ri";
import { FaCode } from "react-icons/fa";
import { MdOutlineAddHomeWork, MdOutlineWorkHistory } from "react-icons/md";
import { PiReadCvLogoFill } from "react-icons/pi";
import { useAuthContext } from "../../context/AuthContext";

const Sidebar = () => {
  const { logOutUser } = useAuthContext();
  let isTabletMid = useMediaQuery({ query: "(max-width: 767px)" });
  const [open, setOpen] = useState(isTabletMid ? false : true);
  const sidebarRef = useRef();
  const { pathname } = useLocation();

  const navigator = useNavigate();

  useEffect(() => {
    if (isTabletMid) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isTabletMid]);

  useEffect(() => {
    isTabletMid && setOpen(false);
  }, [pathname, isTabletMid]);

  const Nav_animation = {
    open: {
      x: 0,
      width: "16rem",
      transition: {
        damping: 40,
      },
    },
    closed: {
      x: -250,
      width: 0,
      transition: {
        damping: 40,
        delay: 0.15,
      },
    },
  };

  // const subMenusList = [
  //   {
  //     name: "Blog",
  //     path: "blog",
  //     icon: BsPerson,
  //     menus: [
  //       { name: "new post", path: "write" },
  //       { name: "drafts", path: "drafts" },
  //       { name: "published", path: "published" },
  //     ],
  //   },
  // ];

  return (
    <div className="fixed">
      <button
        onClick={() => setOpen(false)}
        className={`md:hidden fixed inset-0 z-[998] bg-black/50 ${
          open ? "block" : "hidden"
        } `}
      ></button>

      <motion.div
        ref={sidebarRef}
        variants={Nav_animation}
        initial={{ x: isTabletMid ? -250 : 0 }}
        animate={open ? "open" : "closed"}
        className=" bg-bgDarker text-gray shadow-xl z-[999] max-w-[14rem]  w-[14rem] 
            overflow-y-scoll md:relative fixed h-screen"
      >
        <div className="flex justify-center items-center gap-2.5 font-medium border-b py-10 border-primary mx-3">
          <img src={logo} width={80} alt="" />
        </div>

        <div className="flex flex-col h-full mt-0">
          <ul className="whitespace-pre px-2.5 text-[0.9rem] py-5 flex flex-col gap-1  font-medium overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100  gap-y-4 md:h-[68%] h-[70%]">
            <li>
              <NavLink to={"/dashboard"} className="link">
                <AiOutlineAppstore size={23} className="min-w-max" />
                Dashboard
              </NavLink>
            </li>

            <li>
              <NavLink to={"/applications"} className="link">
                <MdOutlineAddHomeWork size={23} className="min-w-max" />
                Applications
              </NavLink>
            </li>

            {/* <div className=" ">
              {subMenusList?.map((menu) => (
                <div key={menu.name} className="flex flex-col gap-1">
                  <SubMenu data={menu} />
                </div>
              ))}
            </div> */}

            <li>
              <NavLink to={"/education"} className="link">
                <RiGraduationCapFill size={23} className="min-w-max" />
                Education
              </NavLink>
            </li>

            <li>
              <NavLink to={"/projects"} className="link">
                <FaCode size={23} className="min-w-max" />
                Projects
              </NavLink>
            </li>

            <li>
              <NavLink to={"/resume"} className="link">
                <MdOutlineWorkHistory size={23} className="min-w-max" />
                Resume
              </NavLink>
            </li>
            <li>
              <NavLink to={"/skills"} className="link">
                <RiToolsLine size={23} className="min-w-max" />
                Skills
              </NavLink>
            </li>

            <li>
              <NavLink to={"/blog"} className="link">
                <PiReadCvLogoFill size={23} className="min-w-max" />
                Blog
              </NavLink>
            </li>

            {/* <li>
              <NavLink to={"/transactions"} className="link">
                <GrTransaction size={23} className="min-w-max" />
                Blog
              </NavLink>
            </li> */}

            {/* <div className=" ">
              {subMenusList?.map((menu) => (
                <div key={menu.name} className="flex flex-col gap-1">
                  <SubMenu data={menu} />
                </div>
              ))}
            </div> */}

            <li>
              <NavLink to={"/settings"} className="link">
                <SlSettings size={23} className="min-w-max" />
                Settings
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="absolute h-fit md:block w-full mx-6 z-50 left-0 right-2 bottom-10 cursor-pointer">
          <button
            onClick={logOutUser}
            className="py-2 flex gap-4 items-center text-primary"
          >
            <IoIosLogOut size={23} />
            Logout
          </button>
        </div>
      </motion.div>

      <div className="md:hidden flex justify-between w-[100vw] shadow-xl p-3 items-center bg-bgDarker border-b border-primary shadow-primary/5">
        <button onClick={() => setOpen(true)}>
          <AiOutlineMenuUnfold size={25} className="text-primary" />
        </button>

        <div className="flex gap-4 px-3 items-center">
          {/* <button>
            <IoNotificationsOutline size={25} className="min-w-max" />
          </button> */}
          <button
            className="bg-primary px-[10px] py-[8px] rounded-full"
            onClick={() => navigator("/profile")}
          >
            FO
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
