import React, { createContext, useContext, useState } from "react";
import { apiConnect } from "../api/axios";
import toast from "react-hot-toast";

const ProjectsContext = createContext();

export const ProjectsProvider = ({ children }) => {
  const [allProjects, setAllProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [projectDetails, setProjectDetails] = useState(null);

  function handleError(error) {
    const { response } = error;
    toast.error(
      response?.data?.message ?? "An error occurred, please try again!",
      { position: "top-center" }
    );
  }

  const getAllProjects = async () => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getAllProjects();

      if (response.status === 200) {
        setAllProjects(response.data);
      } else {
        toast.error("Failed to fetch projects. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createProject = async (newProject) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.createProjects(newProject);

      if (response.status === 201) {
        toast.success("Project created successfully!");
      } else {
        toast.error("Failed to create project. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getProjectById = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getProjectsById(id);

      if (response.status === 200) {
        setProjectDetails(response.data);
      } else if (response.status === 404) {
        toast.error("Project not found.");
      } else {
        toast.error("Failed to fetch project details. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateProjectById = async (id, updatedProject) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.updateProjectsById(id, updatedProject);

      if (response.status === 200) {
        toast.success("Project updated successfully!");
      } else if (response.status === 404) {
        toast.error("Project not found.");
      } else {
        toast.error("Failed to update project. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteProjectById = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.deleteProjectsById(id);

      if (response.status === 204) {
        // Handle successful deletion
      } else if (response.status === 404) {
        toast.error("Project not found.");
      } else {
        toast.error("Failed to delete project. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ProjectsContext.Provider
      value={{
        allProjects,
        isLoading,
        projectDetails,
        getAllProjects,
        getProjectById,
        updateProjectById,
        deleteProjectById,
        createProject,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

export const useProjectsContext = () => {
  const context = useContext(ProjectsContext);
  if (!context) {
    throw new Error(
      "useProjectsContext must be used within a ProjectsProvider"
    );
  }
  return context;
};
