import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle";
import { useEducationContext } from "../../context/EducationContext";
import { BeatLoader } from "react-spinners";
import { formatFormDate } from "../../utils/formatDate";

const UpdateEducationPage = () => {
  const { isLoading, getEducationById, updateEducationById, educationDetails } =
    useEducationContext();
  const { educationID } = useParams();
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      institution: educationDetails.institution || "",
      type: educationDetails.type || "",
      fieldOfStudy: educationDetails.fieldOfStudy || "",
      startDate: formatFormDate(educationDetails.startDate) || "",
      endDate: formatFormDate(educationDetails.endDate) || "",
      description: educationDetails.description || "",
    },
    validationSchema: Yup.object({
      institution: Yup.string().required("Required"),
      type: Yup.string().required("Required"),
      fieldOfStudy: Yup.string().required("Required"),
      startDate: Yup.date().required("Required"),
      endDate: Yup.date(),
      description: Yup.string(),
    }),
    onSubmit: (values) => {
      // Handle form submission here
      // You can send the data to the backend API here
      updateEducationById(educationID, values).then(() => {
        formik.resetForm();
        navigate("/education");
      });
    },
  });

  useEffect(() => {
    // Fetch education data based on the ID from the URL parameters
    getEducationById(educationID);
  }, [educationID]);

  return (
    <div className="p-4 md:p-12">
      <SectionTitle pageName={"Education"} title={"Update education record"} />

      <div className="flex flex-col items-center justify-center w-full bg-bgDark py-6">
        <div className="text-gray-400 w-full border-[1px] border-gray-600 px-6 py-10 rounded shadow-xl shadow-primary/10">
          <form className="pb-4" onSubmit={formik.handleSubmit}>
            <div className="flex flex-col md:flex-row w-full md:gap-x-6">
              <div className="mb-6 w-full">
                <label
                  htmlFor="institution"
                  className="block text-sm font-medium text-gray-500"
                >
                  Institution
                </label>
                <input
                  type="text"
                  id="institution"
                  name="institution"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.institution && formik.errors.institution
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.institution}
                />
                {formik.touched.institution && formik.errors.institution && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.institution}
                  </div>
                )}
              </div>

              <div className="mb-6 w-full">
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-500"
                >
                  Education Type
                </label>
                <input
                  type="text"
                  id="type"
                  name="type"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.type && formik.errors.type
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.type}
                />
                {formik.touched.type && formik.errors.type && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.type}
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row w-full md:gap-x-6">
              <div className="mb-6 w-full">
                <label
                  htmlFor="startDate"
                  className="block text-sm font-medium text-gray-500"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.startDate && formik.errors.startDate
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.startDate}
                />
                {formik.touched.startDate && formik.errors.startDate && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.startDate}
                  </div>
                )}
              </div>

              <div className="mb-6 w-full">
                <label
                  htmlFor="endDate"
                  className="block text-sm font-medium text-gray-500"
                >
                  End Date
                </label>
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.endDate}
                />
                {formik.touched.endDate && formik.errors.endDate && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.endDate}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-6">
              <label
                htmlFor="fieldOfStudy"
                className="block text-sm font-medium text-gray-500"
              >
                Field of Study
              </label>
              <input
                type="text"
                id="fieldOfStudy"
                name="fieldOfStudy"
                className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                  formik.touched.fieldOfStudy && formik.errors.fieldOfStudy
                    ? "border-red-500"
                    : "border-none"
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fieldOfStudy}
              />
              {formik.touched.fieldOfStudy && formik.errors.fieldOfStudy && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors.fieldOfStudy}
                </div>
              )}
            </div>

            <div className="mb-6">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-500"
              >
                Description
              </label>
              <textarea
                id="description"
                name="description"
                rows="4"
                className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                  formik.touched.description && formik.errors.description
                    ? "border-red-500"
                    : "border-none"
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
              {formik.touched.description && formik.errors.description && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors.description}
                </div>
              )}
            </div>

            <div className="flex justify-center items-center">
              <button
                type="submit"
                className="bg-transparent hover:bg-amber-400 text-sm text-amber-400 py-3 px-24 border border-amber-400 hover:text-[#1E1E28] rounded-lg"
              >
                {isLoading ? (
                  <div className="flex items-center justify-center gap-4 ">
                    <span>Updating</span>
                    <BeatLoader size={8} className="pt-[2px] text-bgDarker" />
                  </div>
                ) : (
                  `Update`
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateEducationPage;
