import React, { useEffect } from "react";
import SectionTitle from "../../components/SectionTitle";
import ProjectCard from "./components/ProjectCard";
import { MdOutlineAddchart } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useProjectsContext } from "../../context/ProjectContext";

const ProjectPage = () => {
  const { getAllProjects, allProjects } = useProjectsContext();
  const navigator = useNavigate();

  useEffect(() => {
    getAllProjects();
  }, []);

  // console.log(allProjects);

  return (
    <div className="p-4 md:p-12">
      <div className="flex justify-between">
        <SectionTitle pageName={"Projects"} title={"All Projects"} />

        <button
          className="flex items-center gap-x-2 text-primary border border-primary rounded-xl px-2 my-[10px] hover:text-bgDarker hover:bg-primary hover:shadow-xl hover:shadow-primary/10"
          onClick={() => {
            navigator("/projects/add");
          }}
        >
          <span>
            <MdOutlineAddchart size={20} />
          </span>
          <h6>Add Project</h6>
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-12">
        {allProjects.map((project) => (
          <ProjectCard key={project._id} {...project} />
        ))}
      </div>
    </div>
  );
};

export default ProjectPage;
