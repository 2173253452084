import React, { useEffect } from "react";
import SectionTitle from "../../components/SectionTitle";
import { useEducationContext } from "../../context/EducationContext";
import EducationCard from "./components/EducationCard";
import { FaGraduationCap } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Education = () => {
  const { getAllEducations, allEducation } = useEducationContext();

  const navigator = useNavigate();

  useEffect(() => {
    getAllEducations();
  }, []);

  return (
    <div className="p-12">
      <div className="flex justify-between">
        <SectionTitle
          pageName={"Education"}
          title={"All Education and Certifications"}
        />

        <button
          className="flex items-center gap-x-2 text-primary border border-primary rounded-xl px-2 my-[10px] hover:text-bgDarker hover:bg-primary hover:shadow-xl hover:shadow-primary/10"
          onClick={() => {
            navigator("/education/add");
          }}
        >
          <span>
            <FaGraduationCap size={25} />
          </span>
          <h6>Add Education</h6>
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-6 mt-12">
        {allEducation.map((val, i) => (
          <EducationCard key={i} {...val} />
        ))}
      </div>
    </div>
  );
};

export default Education;
