import { Navigate } from "react-router-dom";
import MinimalLayout from "../components/layout/MinimalLayout";
import Signin from "../pages/Auth/SignIn";
import IsAnon from "../components/IsAnon";

const AuthenticationRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/login",
      element: (
        <IsAnon>
          <Signin />
        </IsAnon>
      ),
    },
    {
      path: "*",
      element: <Navigate to="/page-not-found" />,
    },
  ],
};

export default AuthenticationRoutes;
