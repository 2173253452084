import React from "react";
import { Outlet } from "react-router-dom";
import IsPrivate from "../IsPrivate";
import Sidebar from "../Sidebar/Sidebar";
// import Navbar from "../Navbar";

function MainLayout() {
  return (
    <div>
      <div className="flex">
        {/* sidebar */}
        <div className="md:w-[14rem]">
          <Sidebar />
        </div>

        {/* site content */}
        <main className="bg-zinc-200 flex-1">
          {/* <div className="hidden md:flex">
            <Navbar />
          </div> */}
          <div className="min-h-[100vh] pt-16 md:pt-4 bg-bgDark">
            <IsPrivate>
              <Outlet />
            </IsPrivate>
          </div>
        </main>
      </div>
    </div>
  );
}

export default MainLayout;
