import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle";
import { useResumeContext } from "../../context/ResumeContext";
import { BeatLoader } from "react-spinners";
import { formatFormDate } from "../../utils/formatDate";

const UpdateResumePage = () => {
  const { isLoading, getResumeById, updateResumeById, resumeDetails } =
    useResumeContext();
  const { resumeID } = useParams();
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      company: resumeDetails.company || "",
      position: resumeDetails.position || "",
      startDate: formatFormDate(resumeDetails.startDate) || "",
      endDate: formatFormDate(resumeDetails.endDate) || "",
      isPresent: resumeDetails.isPresent || false,
      location: resumeDetails.location || "",
      description: resumeDetails.description || "",
      skillsUsed: resumeDetails.skillsUsed
        ? resumeDetails.skillsUsed.join(", ")
        : "",
    },
    validationSchema: Yup.object({
      company: Yup.string().required("Required"),
      position: Yup.string().required("Required"),
      startDate: Yup.date().required("Required"),
      endDate: Yup.date(),
      isPresent: Yup.boolean(),
      location: Yup.string(),
      description: Yup.string(),
      skillsUsed: Yup.string(),
    }),
    onSubmit: (values) => {
      values.skillsUsed = values.skillsUsed.split(",");

      updateResumeById(resumeID, values).then(() => {
        formik.resetForm();
        navigate("/resume");
      });
    },
  });

  useEffect(() => {
    // Fetch resume data based on the ID from the URL parameters
    getResumeById(resumeID);
  }, [resumeID]);

  return (
    <div className="p-4 md:p-12">
      <SectionTitle pageName={"Resume"} title={"Update resume record"} />

      <div className="flex flex-col items-center justify-center w-full bg-bgDark py-6">
        <div className="text-gray-400 w-full border-[1px] border-gray-600 px-6 py-10 rounded shadow-xl shadow-primary/10">
          <form className="pb-4" onSubmit={formik.handleSubmit}>
            <div className="flex flex-col md:flex-row w-full md:gap-x-6">
              <div className="mb-6 w-full">
                <label
                  htmlFor="company"
                  className="block text-sm font-medium text-gray-500"
                >
                  Company
                </label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.company && formik.errors.company
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company}
                />
                {formik.touched.company && formik.errors.company && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.company}
                  </div>
                )}
              </div>

              <div className="mb-6 w-full">
                <label
                  htmlFor="position"
                  className="block text-sm font-medium text-gray-500"
                >
                  Position
                </label>
                <input
                  type="text"
                  id="position"
                  name="position"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.position && formik.errors.position
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.position}
                />
                {formik.touched.position && formik.errors.position && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.position}
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row w-full md:gap-x-6">
              <div className="mb-6 w-full">
                <label
                  htmlFor="startDate"
                  className="block text-sm font-medium text-gray-500"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.startDate && formik.errors.startDate
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.startDate}
                />
                {formik.touched.startDate && formik.errors.startDate && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.startDate}
                  </div>
                )}
              </div>

              <div className="mb-6 w-full">
                <label
                  htmlFor="endDate"
                  className="block text-sm font-medium text-gray-500"
                >
                  End Date
                </label>
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.endDate}
                />
                {formik.touched.endDate && formik.errors.endDate && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.endDate}
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row w-full md:gap-x-6">
              <div className="mb-6 w-full">
                <label
                  htmlFor="isPresent"
                  className="block text-sm font-medium text-gray-500"
                >
                  Is Present
                </label>
                <input
                  type="checkbox"
                  id="isPresent"
                  name="isPresent"
                  className={`text-sm p-4 bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.isPresent && formik.errors.isPresent
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  checked={formik.values.isPresent}
                />
              </div>

              <div className="mb-6 w-full">
                <label
                  htmlFor="location"
                  className="block text-sm font-medium text-gray-500"
                >
                  Location
                </label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.location && formik.errors.location
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.location}
                />
                {formik.touched.location && formik.errors.location && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.location}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-6">
              <label
                htmlFor="skillsUsed"
                className="block text-sm font-medium text-gray-500"
              >
                Skills Used
              </label>
              <input
                type="text"
                id="skillsUsed"
                name="skillsUsed"
                className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                  formik.touched.skillsUsed && formik.errors.skillsUsed
                    ? "border-red-500"
                    : "border-none"
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.skillsUsed}
              />
              {formik.touched.skillsUsed && formik.errors.skillsUsed && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors.skillsUsed}
                </div>
              )}
            </div>

            <div className="mb-6">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-500"
              >
                Description
              </label>
              <textarea
                id="description"
                name="description"
                rows="4"
                className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                  formik.touched.description && formik.errors.description
                    ? "border-red-500"
                    : "border-none"
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
              {formik.touched.description && formik.errors.description && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors.description}
                </div>
              )}
            </div>

            <div className="flex justify-center items-center">
              <button
                type="submit"
                className="bg-transparent hover:bg-amber-400 text-sm text-amber-400 py-3 px-24 border border-amber-400 hover:text-[#1E1E28] rounded-lg"
              >
                {isLoading ? (
                  <div className="flex items-center justify-center gap-4 ">
                    <span>Updating</span>
                    <BeatLoader size={8} className="pt-[2px] text-bgDarker" />
                  </div>
                ) : (
                  `Update`
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateResumePage;
