import React, { createContext, useContext, useEffect, useState } from "react";
import { apiConnect } from "../api/axios";
import toast from "react-hot-toast";

const ApplicationContext = createContext();

export const AppProvider = ({ children }) => {
  const [allSentApplications, setAllSentApplications] = useState({});
  const [applicationDetails, setApplicationDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function handleError(error) {
    const { response } = error;
    toast.error(
      response?.data?.message ?? "An error occurred, please try again!",
      { position: "top-center" }
    );
  }

  const getAllApplications = async () => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getAllSentApplications();

      if (response.status === 200) {
        setAllSentApplications(response.data);
      } else {
        toast.error("Failed to fetch data. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchByTag = async (tag) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getJobApplicationByTag(tag);

      if (response.status === 200) {
        // Handle successful response
        setApplicationDetails(response.data);
      } else {
        toast.error("Failed to search by tag. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createApplication = async (newApplication) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.createApplication(newApplication);

      if (response.status === 201) {
        // Successful creation, you may update the state or perform other actions
        toast.success("Application created successfully!");
      } else {
        toast.error("Failed to create application. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteApplication = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.deleteApplicationById(id);

      if (response.status === 204) {
        // Handle successful deletion
        toast.success("Application deleted successfully!");
      } else if (response.status === 404) {
        toast.error("Application not found.");
      } else {
        toast.error("Failed to delete application. Please try again.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Add more functions as needed for your application
  useEffect(() => {
    getAllApplications();
  }, []);

  return (
    <ApplicationContext.Provider
      value={{
        allSentApplications,
        applicationDetails,
        isLoading,
        getAllApplications,
        searchByTag,
        createApplication,
        deleteApplication,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(ApplicationContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
