import React, { useEffect } from "react";
import SectionTitle from "../../components/SectionTitle";
import { RiToolsLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useSkillsContext } from "../../context/SkillsContext";
import TechCard from "./components/TechCard";

function SkillsPage() {
  const navigator = useNavigate();
  const { allSkills, getAllSkills } = useSkillsContext();

  useEffect(() => {
    getAllSkills();
  }, []);
  return (
    <div className="p-12">
      <div className="flex justify-between">
        <SectionTitle pageName={"Skill"} title={"All Skills"} />

        <button
          className="flex items-center gap-x-2 text-primary border border-primary rounded-xl px-2 my-[10px] hover:text-bgDarker hover:bg-primary hover:shadow-xl hover:shadow-primary/10"
          onClick={() => {
            navigator("/skills/add");
          }}
        >
          <span>
            <RiToolsLine size={25} />
          </span>
          <h6>Add Skill</h6>
        </button>
      </div>

      <div className="grid grid-cols-3 gap-4 md:grid-cols-6 mt-6">
        {allSkills.map((skill) => {
          return <TechCard key={skill?._id} {...skill} />;
        })}
      </div>
    </div>
  );
}

export default SkillsPage;
