import React, { createContext, useContext, useState } from "react";
import { apiConnect } from "../api/axios";
import toast from "react-hot-toast";

const EducationContext = createContext();

export const EducationProvider = ({ children }) => {
  const [allEducation, setAllEducation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [educationDetails, setEducationDetails] = useState(false);

  function handleError(error) {
    const { response } = error;
    toast.error(
      response?.data?.message ?? "An error occurred, please try again!",
      { position: "top-center" }
    );
  }

  const getAllEducations = async () => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getAllEducation();

      if (response.status === 200) {
        setAllEducation(response.data);
      } else {
        toast.error("Failed to fetch educations. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createEducation = async (newEducation) => {
    try {
      setIsLoading(true);

      // Replace with your actual API call for creating education
      const response = await apiConnect.createEducation(newEducation);

      if (response.status === 201) {
        // Successful creation, you may update the state or perform other actions
        toast.success("Education created successfully!");
      } else {
        toast.error("Failed to create education. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getEducationById = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getEducationById(id);

      if (response.status === 200) {
        // Handle successful response
        setEducationDetails(response.data);
      } else if (response.status === 404) {
        toast.error("Education not found.");
      } else {
        toast.error(
          "Failed to fetch education details. Please try again later."
        );
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateEducationById = async (id, updatedEducation) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.updateEducationById(
        id,
        updatedEducation
      );

      if (response.status === 200) {
        // Handle successful update
        toast.success("Education Updated!");
      } else if (response.status === 404) {
        toast.error("Education not found.");
      } else {
        toast.error("Failed to update education. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteEducationById = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.deleteEducationById(id);

      if (response.status === 204) {
        // Handle successful deletion
      } else if (response.status === 404) {
        toast.error("Education not found.");
      } else {
        toast.error("Failed to delete education. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EducationContext.Provider
      value={{
        allEducation,
        isLoading,
        educationDetails,
        getAllEducations,
        getEducationById,
        updateEducationById,
        deleteEducationById,
        createEducation,
      }}
    >
      {children}
    </EducationContext.Provider>
  );
};

export const useEducationContext = () => {
  const context = useContext(EducationContext);
  if (!context) {
    throw new Error(
      "useEducationContext must be used within an EducationProvider"
    );
  }
  return context;
};
