import React, { useState } from "react";
import { RiGraduationCapFill } from "react-icons/ri";
import { TiArrowRight } from "react-icons/ti";
import { formatDate } from "../../../utils/formatDate";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { useEducationContext } from "../../../context/EducationContext";

// {
//     _id: '6580524f987b90392a5c31af',
//     institution: 'University of Example',
//     type: 'Bachelor of Science',
//     fieldOfStudy: 'Computer Science',
//     startDate: '2020-09-01T00:00:00.000Z',
//     endDate: '2024-05-30T00:00:00.000Z',
//     description: 'Studied various aspects of computer science and programming.',
//     createdAt: '2023-12-18T14:08:15.119Z',
//     updatedAt: '2023-12-18T14:08:15.119Z',
//     __v: 0
//   },

function EducationCard({
  institution,
  description,
  fieldOfStudy,
  type,
  startDate,
  endDate,
  _id,
}) {
  const { getAllEducations, deleteEducationById } = useEducationContext();
  const navigator = useNavigate();

  const [isDelete, setIsDelete] = useState(false);

  return (
    <div className="border-l border-gray-600 h-max bg-bgDarker pb-5 pr-4">
      <div className="flex justify-between">
        <div className="flex items-center gap-x-4">
          <RiGraduationCapFill
            className="text-primary bg-bgDarker p-[6px] rounded-full -ml-4"
            size={35}
          />

          <span className="px-[10px] bg-gray-700 text-gray-300 rounded-full text-sm uppercase">
            {formatDate(startDate)} {endDate ? "- " + formatDate(endDate) : ""}
          </span>
        </div>

        <div className="gap-6 flex">
          <button>
            <BiEdit
              size={20}
              className="text-green-500"
              onClick={() => navigator(`/education/${_id}/edit`)}
            />
          </button>
          <button>
            <AiOutlineDelete
              size={20}
              className="text-red-500"
              onClick={() => setIsDelete(true)}
            />
          </button>

          {isDelete ? (
            <div className="flex items-center gap-x-3 bg-red-50/10 rounded-full px-3 shadow-xl">
              <button>
                <MdOutlineCancel
                  size={20}
                  onClick={() => {
                    setIsDelete(false);
                  }}
                  className="text-gray-300"
                />
              </button>
              <button>
                <IoCheckmarkCircleOutline
                  size={20}
                  className="text-green-500"
                  onClick={() => {
                    deleteEducationById(_id).then(async () => {
                      await getAllEducations();
                    });
                  }}
                />
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="pl-10 mt-2">
        <h5 className="uppercase text-lg text-gray-400">
          {type} - {fieldOfStudy}
        </h5>
        <h6 className="text-gray-400 text-sm italic">{institution}</h6>

        {description.split("\n").map((val, i) => {
          return (
            <p className="pt-3 text-gray-400 flex text-sm" key={i}>
              <span className="pt-[5px] text-primary pr-2">
                <TiArrowRight />
              </span>
              {val}
            </p>
          );
        })}
      </div>
    </div>
  );
}

export default EducationCard;
