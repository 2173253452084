import React from "react";
import MDEditor from "@uiw/react-md-editor";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useBlogContext } from "../../context/BlogContext";
import * as Yup from "yup";
import { BeatLoader } from "react-spinners";
import SectionTitle from "../../components/SectionTitle";

function WriteBlog() {
  const navigate = useNavigate();

  const { isLoading, createBlog } = useBlogContext();

  const initialValues = {
    title: "",
    blogCoverImage: "",
    blogHeading: "",
    tags: "",
    blogSeries: "",
    blogContent: "**Hello world!!!**",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    blogCoverImage: Yup.string().required("Blog Cover Image Url is required"),
    blogHeading: Yup.string().required("Blog Heading is required"),
    tags: Yup.string(),
    blogSeries: Yup.string().required("Blog Series is required"),
  });

  const onSubmit = (values) => {
    createBlog(values).then(() => {
      formik.resetForm();
      navigate("/blog");
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="p-4 md:p-8 mt-16 lg:mt-0 pb-24 md:pb-0">
      <div className="flex justify-between">
        <SectionTitle pageName={"Blog"} title={"Write new blog"} />

        <button
          className="flex items-center gap-x-2 text-primary border border-primary rounded-xl px-2 my-[10px] hover:text-bgDarker hover:bg-primary hover:shadow-xl hover:shadow-primary/10"
          onClick={() => {
            // navigator("/education/add");
          }}
        >
          {/* <span>
            <FaGraduationCap size={25} />
          </span> */}
          <h6>Publish External</h6>
        </button>
      </div>

      <section className="flex flex-col md:flex-row w-full mt-6 justify-between gap-x-2">
        <div className="w-[100%] md:w-[70%] lg:w-[70%] min-h-[100vh] md:mt-0 overflow-y-scroll">
          <MDEditor
            value={formik.values.blogContent}
            onChange={(value) => formik.setFieldValue("blogContent", value)}
            enableScroll={true}
            style={{ minWidth: "50%", minHeight: "95vh" }}
          />
        </div>

        <aside className="w-full md:w-[30%] p-4 bg-[#242430] h-max">
          <h3 className="text-lg text-primary">Blog Metadata</h3>

          <hr className="my-3 border-gray-600" />

          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col gap-1">
              <label htmlFor="title" className="font-sm text-sm text-gray-400">
                Blog Title*
              </label>
              <input
                type={"text"}
                name="title"
                onChange={formik.handleChange}
                value={formik.values.title}
                className={
                  formik.errors.title
                    ? "focus:outline-none focus:border-red-500  border border-red-500  text-gray-400 p-2 bg-[#191923] rounded-md"
                    : "text-gray-400 p-2 bg-[#191923] rounded-md"
                }
              />
              {formik.errors.title && (
                <span className="text-red-400 text-sm">
                  {formik.errors.title}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-1 mt-3">
              <label
                htmlFor="blgoHeading"
                className="font-sm text-sm text-gray-400"
              >
                Blog Headline*
              </label>
              <input
                type="text"
                name="blogHeading"
                onChange={formik.handleChange}
                value={formik.values.blogHeading}
                className={
                  formik.errors.blogHeading
                    ? "focus:outline-none focus:border-red-500  border border-red-500  text-gray-400 p-2 bg-[#191923] rounded-md"
                    : "text-gray-400 p-2 bg-[#191923] rounded-md"
                }
              />
              {formik.errors.blogHeading && (
                <span className="text-red-400 text-sm">
                  {formik.errors.blogHeading}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-1 mt-3">
              <label
                htmlFor="blogSeries"
                className="font-sm text-sm text-gray-400"
              >
                Blog Series*
              </label>
              <input
                type="text"
                name="blogSeries"
                onChange={formik.handleChange}
                value={formik.values.blogSeries}
                // placeholder="e.g. Web"
                className={
                  formik.errors.blogSeries
                    ? "focus:outline-none focus:border-red-500  border border-red-500 placeholder:text-sm text-gray-400 p-2 bg-[#191923] rounded-md"
                    : "text-gray-400 p-2 bg-[#191923] rounded-md"
                }
              />
              {formik.errors.blogSeries && (
                <span className="text-red-400 text-sm">
                  {formik.errors.blogSeries}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-1 mt-4">
              <label
                htmlFor="blogCoverImage"
                className="font-sm text-sm text-gray-400"
              >
                Blog Cover Image Url*
              </label>
              <input
                type={"text"}
                name="blogCoverImage"
                onChange={formik.handleChange}
                value={formik.values.blogCoverImage}
                className={
                  formik.errors.blogCoverImage
                    ? "focus:outline-none focus:border-red-500  border border-red-500  text-gray-400 p-2 bg-[#191923] rounded-md"
                    : "text-gray-400 p-2 bg-[#191923] rounded-md"
                }
              />
              {formik.errors.blogCoverImage && (
                <span className="text-red-400 text-sm">
                  {formik.errors.blogCoverImage}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-1 mt-3">
              <label htmlFor="tags" className="font-sm text-sm text-gray-400">
                Tags
              </label>
              <input
                type="text"
                name="tags"
                onChange={formik.handleChange}
                value={formik.values.tags}
                // placeholder="e.g. Web"
                className={
                  formik.errors.tags
                    ? "focus:outline-none focus:border-red-500  border border-red-500 placeholder:text-sm text-gray-400 p-2 bg-[#191923] rounded-md"
                    : "text-gray-400 p-2 bg-[#191923] rounded-md"
                }
              />
              {formik.errors.tags && (
                <span className="text-red-400 text-sm">
                  {formik.errors.tags}
                </span>
              )}
            </div>

            {/* Repeat similar changes for other form fields */}

            <div className="flex justify-center items-center mt-8">
              <button
                type="submit"
                className="bg-transparent hover:bg-amber-400 text-sm text-amber-400 py-2 w-full px-4 border border-amber-400 hover:text-[#1E1E28] rounded-lg"
              >
                {isLoading ? (
                  <div className="flex items-center justify-center gap-4 ">
                    <span>Saving</span>
                    <BeatLoader size={8} className="pt-[2px] text-bgDarker" />
                  </div>
                ) : (
                  `Save Draft`
                )}
              </button>
            </div>
          </form>
        </aside>
      </section>
    </div>
  );
}

export default WriteBlog;
