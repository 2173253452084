import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle";
import { useProjectsContext } from "../../context/ProjectContext";
import { BeatLoader } from "react-spinners";

const AddProjectPage = () => {
  const { isLoading, createProject } = useProjectsContext();
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);

  const formik = useFormik({
    initialValues: {
      projectName: "",
      projectCoverImage: "",
      projectGithubLink: "",
      projectViewLink: "",
      projectCategory: "",
      projectClient: "",
      projectStartDate: "",
      projectEndDate: "",
      projectStack: "", //should be an array. use .split("\n") to convert it to an array when submitting
      projectFeatures: "", //should be an array. use .split("\n") to convert it to an array when submitting
      projectDescription: "",
      projectImages: [],
    },
    validationSchema: Yup.object({
      projectName: Yup.string().required("Required"),
      projectCoverImage: Yup.mixed().required("Required"),
      // Add validations for other fields as needed
    }),
    onSubmit: (values) => {
      // Handle form submission here
      // console.log("Form submitted with values:", values);

      // Convert array-like fields to appropriate formats
      const formData = new FormData();
      formData.append("projectName", values.projectName);
      formData.append("projectCoverImage", values.projectCoverImage);

      // Convert array fields to proper formats
      values.projectStack = values.projectStack.split(",");
      values.projectFeatures = values.projectFeatures.split("\n");

      formData.append("projectStack", JSON.stringify(values.projectStack));
      formData.append(
        "projectFeatures",
        JSON.stringify(values.projectFeatures)
      );

      // Append other fields to FormData
      formData.append("projectGithubLink", values.projectGithubLink);
      formData.append("projectViewLink", values.projectViewLink);
      formData.append("projectCategory", values.projectCategory);
      formData.append("projectClient", values.projectClient);
      formData.append("projectStartDate", values.projectStartDate);
      formData.append("projectEndDate", values.projectEndDate);
      formData.append("projectDescription", values.projectDescription);

      // Append each image to FormData
      values.projectImages.forEach((image) => {
        formData.append(`projectImages`, image);
      });

      // You can send the FormData to the backend API here
      createProject(formData).then(() => {
        formik.resetForm();
        navigate("/projects");
      });
    },
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("projectCoverImage", file);
    setPreviewImage(URL.createObjectURL(file));
  };

  const handleProjectImageChange = (e) => {
    const files = e.target.files;
    const imagesArray = Array.from(files);

    formik.setFieldValue("projectImages", imagesArray);

    const imagePreviews = imagesArray.map((image) =>
      URL.createObjectURL(image)
    );
    setPreviewImages(imagePreviews);
  };

  return (
    <div className="p-4 md:p-12">
      <SectionTitle pageName={"Projects"} title={"Add new project record"} />

      <div className="flex flex-col items-center justify-center w-full bg-bgDark py-6">
        <div className="text-gray-400 w-full border-[1px] border-gray-600 px-6 py-10 rounded shadow-xl shadow-primary/10">
          <form className="pb-4" onSubmit={formik.handleSubmit}>
            <div className="mb-3 w-full">
              {previewImage && (
                <img
                  src={previewImage}
                  alt="Project Cover Preview"
                  className="mt-2 w-full h-[20rem] object-cover rounded mb-4"
                />
              )}

              <div className="flex flex-col md:flex-row md:justify-between md:gap-x-4">
                <div className="w-full md:w-1/2">
                  <label
                    htmlFor="projectCoverImage"
                    className="block text-sm font-medium text-gray-500"
                  >
                    Project Cover Image *
                  </label>
                  <input
                    type="file"
                    id="projectCoverImage"
                    name="projectCoverImage"
                    accept="image/*"
                    className={`text-sm w-full p-3 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                      formik.touched.projectCoverImage &&
                      formik.errors.projectCoverImage
                        ? "border-red-500"
                        : "border-none"
                    }`}
                    onChange={handleImageChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.projectCoverImage &&
                    formik.errors.projectCoverImage && (
                      <div className="text-red-500 text-sm mt-2">
                        {formik.errors.projectCoverImage}
                      </div>
                    )}
                </div>

                {/* project name */}
                <div className="w-full md:w-1/2">
                  <label
                    htmlFor="projectName"
                    className="block text-sm font-medium text-gray-500"
                  >
                    Project Name *
                  </label>
                  <input
                    type="text"
                    id="projectName"
                    name="projectName"
                    className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                      formik.touched.projectName && formik.errors.projectName
                        ? "border-red-500"
                        : "border-none"
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.projectName}
                  />
                  {formik.touched.projectName && formik.errors.projectName && (
                    <div className="text-red-500 text-sm mt-2">
                      {formik.errors.projectName}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* github link && preview link */}
            <div className="flex flex-col md:flex-row md:justify-between md:gap-x-4 mb-6">
              {/* project github link */}
              <div className="w-full">
                <label
                  htmlFor="projectGithubLink"
                  className="block text-sm font-medium text-gray-500"
                >
                  Project GitHub Link
                </label>
                <input
                  type="text"
                  id="projectGithubLink"
                  name="projectGithubLink"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.projectGithubLink &&
                    formik.errors.projectGithubLink
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.projectGithubLink}
                />
                {formik.touched.projectGithubLink &&
                  formik.errors.projectGithubLink && (
                    <div className="text-red-500 text-sm mt-2">
                      {formik.errors.projectGithubLink}
                    </div>
                  )}
              </div>

              {/* project preview link */}
              <div className="w-full">
                <label
                  htmlFor="projectViewLink"
                  className="block text-sm font-medium text-gray-500"
                >
                  Project Preview Link
                </label>
                <input
                  type="text"
                  id="projectViewLink"
                  name="projectViewLink"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.projectViewLink &&
                    formik.errors.projectViewLink
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.projectViewLink}
                />
                {formik.touched.projectViewLink &&
                  formik.errors.projectViewLink && (
                    <div className="text-red-500 text-sm mt-2">
                      {formik.errors.projectViewLink}
                    </div>
                  )}
              </div>
            </div>

            {/* Category && Client */}
            <div className="flex flex-col md:flex-row md:justify-between md:gap-x-4 mb-6">
              {/* Project Category */}
              <div className="w-full">
                <label
                  htmlFor="projectCategory"
                  className="block text-sm font-medium text-gray-500"
                >
                  Project Category
                </label>
                <input
                  type="text"
                  id="projectCategory"
                  name="projectCategory"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.projectCategory &&
                    formik.errors.projectCategory
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.projectCategory}
                />
                {formik.touched.projectCategory &&
                  formik.errors.projectCategory && (
                    <div className="text-red-500 text-sm mt-2">
                      {formik.errors.projectCategory}
                    </div>
                  )}
              </div>

              {/* Project Client */}
              <div className="w-full">
                <label
                  htmlFor="projectClient"
                  className="block text-sm font-medium text-gray-500"
                >
                  Project Client
                </label>
                <input
                  type="text"
                  id="projectClient"
                  name="projectClient"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.projectClient && formik.errors.projectClient
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.projectClient}
                />
                {formik.touched.projectClient &&
                  formik.errors.projectClient && (
                    <div className="text-red-500 text-sm mt-2">
                      {formik.errors.projectClient}
                    </div>
                  )}
              </div>
            </div>

            {/* Start && End dates */}
            <div className="flex flex-col md:flex-row md:justify-between md:gap-x-4 mb-6">
              {/* Project Start */}
              <div className="w-full">
                <label
                  htmlFor="projectStartDate"
                  className="block text-sm font-medium text-gray-500"
                >
                  Project Start Date
                </label>
                <input
                  type="date"
                  id="projectStartDate"
                  name="projectStartDate"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.projectStartDate &&
                    formik.errors.projectStartDate
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.projectStartDate}
                />
                {formik.touched.projectStartDate &&
                  formik.errors.projectStartDate && (
                    <div className="text-red-500 text-sm mt-2">
                      {formik.errors.projectStartDate}
                    </div>
                  )}
              </div>

              {/* Project End Date */}
              <div className="w-full">
                <label
                  htmlFor="projectEndDate"
                  className="block text-sm font-medium text-gray-500"
                >
                  Project End Date
                </label>
                <input
                  type="date"
                  id="projectEndDate"
                  name="projectEndDate"
                  className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                    formik.touched.projectEndDate &&
                    formik.errors.projectEndDate
                      ? "border-red-500"
                      : "border-none"
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.projectEndDate}
                />
                {formik.touched.projectEndDate &&
                  formik.errors.projectEndDate && (
                    <div className="text-red-500 text-sm mt-2">
                      {formik.errors.projectEndDate}
                    </div>
                  )}
              </div>
            </div>

            {/* project stack */}
            <div className="w-full mb-6">
              <label
                htmlFor="projectStack"
                className="block text-sm font-medium text-gray-500"
              >
                Project Stack
              </label>
              <input
                type="text"
                id="projectStack"
                name="projectStack"
                className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                  formik.touched.projectStack && formik.errors.projectStack
                    ? "border-red-500"
                    : "border-none"
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.projectStack}
              />
              {formik.touched.projectStack && formik.errors.projectStack && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors.projectStack}
                </div>
              )}
            </div>

            {/* Project Features */}
            <div className="w-full mb-6">
              <label
                htmlFor="projectStack"
                className="block text-sm font-medium text-gray-500"
              >
                Project Features
              </label>
              <textarea
                rows={5}
                type="text"
                id="projectFeatures"
                name="projectFeatures"
                className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                  formik.touched.projectFeatures &&
                  formik.errors.projectFeatures
                    ? "border-red-500"
                    : "border-none"
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.projectFeatures}
              />
              {formik.touched.projectFeatures &&
                formik.errors.projectFeatures && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.projectFeatures}
                  </div>
                )}
            </div>

            {/* Project Description */}
            <div className="w-full mb-6">
              <label
                htmlFor="projectDescription"
                className="block text-sm font-medium text-gray-500"
              >
                Project Description
              </label>
              <textarea
                rows={5}
                type="text"
                id="projectDescription"
                name="projectDescription"
                className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                  formik.touched.projectDescription &&
                  formik.errors.projectDescription
                    ? "border-red-500"
                    : "border-none"
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.projectDescription}
              />
              {formik.touched.projectDescription &&
                formik.errors.projectDescription && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.projectDescription}
                  </div>
                )}
            </div>

            {/* project images */}
            <div className="mb-6 w-full">
              <label
                htmlFor="projectImages"
                className="block text-sm font-medium text-gray-500"
              >
                Project Images *
              </label>
              <input
                type="file"
                id="projectImages"
                name="projectImages"
                accept="image/*"
                multiple
                className={`text-sm w-full p-3 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
                  formik.touched.projectImages && formik.errors.projectImages
                    ? "border-red-500"
                    : "border-none"
                }`}
                onChange={handleProjectImageChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.projectImages && formik.errors.projectImages && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors.projectImages}
                </div>
              )}

              <div className="flex space-x-2 mt-2 overflow-x-scroll">
                {previewImages.map((preview, index) => (
                  <img
                    key={index}
                    src={preview}
                    alt={`Project Image ${index + 1}`}
                    className="w-20 h-20 object-cover rounded"
                  />
                ))}
              </div>
            </div>

            <div className="flex justify-center items-center">
              <button
                type="submit"
                className="bg-transparent hover:bg-amber-400 text-sm text-amber-400 py-3 px-24 border border-amber-400 hover:text-[#1E1E28] rounded-lg"
              >
                {isLoading ? (
                  <div className="flex items-center justify-center gap-4 ">
                    <span>Submitting</span>
                    <BeatLoader size={8} className="pt-[2px] text-bgDarker" />
                  </div>
                ) : (
                  `Submit`
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddProjectPage;
