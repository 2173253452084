import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App.jsx";
import "./index.css";
import { AuthProvider } from "./context/AuthContext.js";
import { EducationProvider } from "./context/EducationContext.js";
import { ResumeProvider } from "./context/ResumeContext.js";
import { ProjectsProvider } from "./context/ProjectContext.js";
import { BlogProvider } from "./context/BlogContext.js";
import { UserProvider } from "./context/UserProfileContext.js";
import { SkillsProvider } from "./context/SkillsContext.js";
import { AppProvider } from "./context/ApplicationContext.js";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthProvider>
      <EducationProvider>
        <ResumeProvider>
          <ProjectsProvider>
            <BlogProvider>
              <UserProvider>
                <SkillsProvider>
                  <AppProvider>
                    <App />
                  </AppProvider>
                </SkillsProvider>
              </UserProvider>
            </BlogProvider>
          </ProjectsProvider>
        </ResumeProvider>
      </EducationProvider>
    </AuthProvider>
  </React.StrictMode>
);
