import React, { useEffect } from "react";
import SectionTitle from "../../components/SectionTitle";
import BlogCard from "./components/BlogCard";
import { useBlogContext } from "../../context/BlogContext";
import BlogShimmer from "./components/BlogShimmer";
import { useNavigate } from "react-router-dom";

import { FaFileAlt } from "react-icons/fa";

const AllBlogPage = () => {
  const { allBlogs, isLoading, getAllBlogs } = useBlogContext();
  const navigator = useNavigate();

  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <div className="px-8 py-24 md:p-12 h-full overflow-hidden">
      <div className="flex justify-between">
        <SectionTitle pageName={"Blog"} title={"All Blog Posts"} />

        <button
          className="flex items-center gap-x-2 text-primary border border-primary rounded-xl px-2 my-[10px] hover:text-bgDarker hover:bg-primary hover:shadow-xl hover:shadow-primary/10"
          onClick={() => {
            navigator("/blog/write");
          }}
        >
          <span>
            <FaFileAlt size={25} />
          </span>
          <h6>Write Blog</h6>
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
        {isLoading
          ? Array.from({ length: 6 }).map((_, index) => (
              <BlogShimmer key={index} />
            ))
          : allBlogs.map((post) => <BlogCard key={post._id} {...post} />)}
      </div>
    </div>
  );
};

export default AllBlogPage;
