import React, { useState } from "react";
import { TiArrowRight } from "react-icons/ti";
import { formatDate } from "../../../utils/formatDate";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { MdWorkHistory } from "react-icons/md";
import { useResumeContext } from "../../../context/ResumeContext";

function ResumeCard({
  description,
  startDate,
  endDate,
  _id,
  company,
  position,
  skillsUsed,
  isPresent,
  location,
}) {
  const { getAllResumes, deleteResumeById } = useResumeContext();
  const navigator = useNavigate();

  const [isDelete, setIsDelete] = useState(false);

  // {
  //   _id: '65805efc3f4faed87d61734c',
  //   company: 'Tech Solutions Incorp.',
  //   position: 'Software Engineer',
  //   startDate: '2019-07-01T00:00:00.000Z',
  //   endDate: '2022-09-30T00:00:00.000Z',
  //   isPresent: false,
  //   description:
  //     'Developed and maintained software applications for clients. Collaborated with cross-functional teams.',
  //   skillsUsed: [ 'JavaScript', 'Node.js', 'React', 'MongoDB' ],
  //   createdAt: '2023-12-18T15:02:20.613Z',
  //   updatedAt: '2023-12-18T15:04:22.997Z',
  //   __v: 0
  // },

  return (
    <div className="border-l border-gray-600 h-max bg-bgDarker pb-3 pr-4 rounded-tl-lg">
      <div className="flex justify-between">
        <div className="flex items-center gap-x-5">
          <MdWorkHistory
            className="text-primary bg-bgDarker p-[6px] rounded-full -ml-4"
            size={35}
          />

          {/* <span className="px-[10px] bg-gray-700 text-gray-300 rounded-full text-sm uppercase">
         
            {company}
          </span> */}

          <h5 className="uppercase text-lg text-primary pt-3">{position}</h5>
        </div>

        <div className="gap-6 flex">
          <button>
            <BiEdit
              size={20}
              className="text-green-500"
              onClick={() => navigator(`/resume/${_id}/edit`)}
            />
          </button>
          <button>
            <AiOutlineDelete
              size={20}
              className="text-red-500"
              onClick={() => setIsDelete(true)}
            />
          </button>

          {isDelete ? (
            <div className="flex items-center gap-x-3 bg-red-50/10 rounded-full px-3 shadow-xl">
              <button>
                <MdOutlineCancel
                  size={20}
                  onClick={() => {
                    setIsDelete(false);
                  }}
                  className="text-gray-300"
                />
              </button>
              <button>
                <IoCheckmarkCircleOutline
                  size={20}
                  className="text-green-500"
                  onClick={() => {
                    deleteResumeById(_id).then(async () => {
                      await getAllResumes();
                    });
                  }}
                />
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="pl-10">
        <div className="flex justify-between">
          <h6 className="text-gray-400 text-sm italic">
            {company}
            {location ? ` - ${location}` : ""}
          </h6>
          <h6 className="px-[10px] bg-gray-700 text-gray-300 rounded-full text-sm uppercase">
            {formatDate(startDate)}
            {isPresent ? "-Present" : endDate ? "- " + formatDate(endDate) : ""}
          </h6>
        </div>

        {skillsUsed && (
          <div className="mt-2 flex gap-2">
            {skillsUsed?.map((val, i) => {
              return (
                <p
                  key={i}
                  className="text-primary border-[1px] border-primary px-2 text-sm flex-wrap rounded-full"
                >
                  {val}
                </p>
              );
            })}
          </div>
        )}

        {description.split("\n").map((val, i) => {
          return (
            <p className="pt-4 text-gray-400 flex text-sm items-center" key={i}>
              <span className="pt-[5px] text-primary pr-2">
                <TiArrowRight size={18} />
              </span>
              {val}
            </p>
          );
        })}
      </div>
    </div>
  );
}

export default ResumeCard;
