import React, { useState } from "react";
import { GoLinkExternal } from "react-icons/go";
import { useProjectsContext } from "../../../context/ProjectContext";
import { useNavigate } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";

function ProjectCard({
  projectName,
  projectCoverImage,
  projectViewLink,
  projectDescription,
  _id,
}) {
  // console.log(projectCoverImage);

  const [isDelete, setIsDelete] = useState(false);

  const { getAllProjects, deleteProjectById } = useProjectsContext();
  const navigator = useNavigate();

  return (
    <div className="relative bg-bgDarker rounded-lg shadow-lg overflow-hidden group">
      <img
        className="w-full h-[250px] md:h-[300px] object-cover object-center overflow-hidden transform scale-100 transition-transform duration-300 group-hover:scale-110"
        src={`${projectCoverImage}`}
        alt={projectName}
      />

      <div className="md:absolute bottom-0 md:top-32 w-full h-full bg-bgDarker md:bg-bgDarker/90 transform md:translate-y-full transition-transform duration-500 group-hover:translate-y-0">
        <div className="p-6 text-white">
          <div className="flex justify-between">
            <h2 className="text-xl text-gray-300 font-bold mb-2">
              {projectName}
            </h2>

            <div className="gap-2 flex">
              <button>
                <BiEdit
                  size={20}
                  className="text-green-500"
                  onClick={() => navigator(`/projects/${_id}/edit`)}
                />
              </button>
              <button>
                <AiOutlineDelete
                  size={20}
                  className="text-red-500"
                  onClick={() => setIsDelete(true)}
                />
              </button>

              {isDelete ? (
                <div className="flex items-center gap-x-3 bg-red-50/10 rounded-full px-3 shadow-xl">
                  <button>
                    <MdOutlineCancel
                      size={20}
                      onClick={() => {
                        setIsDelete(false);
                      }}
                      className="text-gray-300"
                    />
                  </button>
                  <button>
                    <IoCheckmarkCircleOutline
                      size={20}
                      className="text-green-500"
                      onClick={() => {
                        deleteProjectById(_id).then(async () => {
                          await getAllProjects();
                        });
                      }}
                    />
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <p className="my-4 text-sm h-10 text-ellipsis overflow-hidden text-gray-400">
            {projectDescription}
          </p>

          <div className="flex justify-between items-center mt-4">
            <button className="text-primary font-bold hover:text-bgDarker text-sm">
              Read More
            </button>

            {projectViewLink && (
              <a href={projectViewLink} target="_blank">
                <GoLinkExternal />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
