import React, { useEffect } from "react";
import { useAppContext } from "../../context/ApplicationContext";
import SectionTitle from "../../components/SectionTitle";
import { useNavigate } from "react-router-dom";
import { MdOutlineAddHomeWork } from "react-icons/md";
import ApplicationCard from "./components/ApplicationCard";

function ApplicationsPage() {
  const { allSentApplications, getAllApplications } = useAppContext();
  const navigator = useNavigate();
  
  useEffect(() => {
    getAllApplications();
  }, []);

  return (
    <div className="p-12">
      <div className="flex justify-between">
        <SectionTitle
          pageName={"Job Applications"}
          title={"All Job Applications"}
        />

        <button
          className="flex items-center gap-x-2 text-primary border border-primary rounded-xl px-2 my-[10px] hover:text-bgDarker hover:bg-primary hover:shadow-xl hover:shadow-primary/10"
          onClick={() => {
            navigator("/applications/add");
          }}
        >
          <span>
            <MdOutlineAddHomeWork size={25} />
          </span>
          <h6>Add Job Application</h6>
        </button>
      </div>

      <main className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-12">
        {allSentApplications?.map((application) => {
          return <ApplicationCard {...application} key={application._id} />;
        })}
      </main>
    </div>
  );
}

export default ApplicationsPage;
