import React, { createContext, useContext, useEffect, useState } from "react";
import { apiConnect } from "../api/axios";
import toast from "react-hot-toast";

// Rename the context to SkillsContext
const SkillsContext = createContext();

// Correct the export name to SkillsProvider
export const SkillsProvider = ({ children }) => {
  const [allSkills, setAllSkills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [skillDetails, setSkillDetails] = useState(false);

  function handleError(error) {
    const { response } = error;
    toast.error(
      response?.data?.message ?? "An error occurred, please try again!",
      { position: "top-center" }
    );
  }

  const getAllSkills = async () => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getAllSkills();

      if (response.status === 200) {
        setAllSkills(response.data);
      } else {
        toast.error("Failed to fetch skills. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createSkill = async (newSkill) => {
    try {
      setIsLoading(true);

      // Replace with your actual API call for creating a skill
      const response = await apiConnect.createSkill(newSkill);

      if (response.status === 201) {
        // Successful creation, you may update the state or perform other actions
        toast.success("Skill created successfully!");
      } else {
        toast.error("Failed to create skill. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSkillById = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.deleteSkillById(id);

      if (response.status === 204) {
        // Handle successful deletion
      } else if (response.status === 404) {
        toast.error("Skill not found.");
      } else {
        toast.error("Failed to delete skill. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllSkills();
  }, []);

  return (
    <SkillsContext.Provider
      value={{
        allSkills,
        isLoading,
        getAllSkills,
        deleteSkillById,
        createSkill,
      }}
    >
      {children}
    </SkillsContext.Provider>
  );
};

export const useSkillsContext = () => {
  const context = useContext(SkillsContext);
  if (!context) {
    throw new Error("useSkillsContext must be used within a SkillsProvider");
  }
  return context;
};
