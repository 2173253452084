import React, { createContext, useContext, useEffect, useState } from "react";
import { apiConnect } from "../api/axios";
import toast from "react-hot-toast";

const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
  const [allBlogs, setAllBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [blogDetails, setBlogDetails] = useState(null);

  function handleError(error) {
    const { response } = error;
    toast.error(
      response?.data?.message ?? "An error occurred, please try again!",
      { position: "top-center" }
    );
  }

  const getAllBlogs = async () => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getAllBlogs();

      if (response.status === 200) {
        setAllBlogs(response.data);
      } else {
        toast.error("Failed to fetch blogs. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createBlog = async (newBlog) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.createBlog(newBlog);

      if (response.status === 201) {
        toast.success("Blog created successfully!");
      } else {
        toast.error("Failed to create blog. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getBlogById = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getBlogById(id);

      if (response.status === 200) {
        setBlogDetails(response.data);
      } else if (response.status === 404) {
        toast.error("Blog not found.");
      } else {
        toast.error("Failed to fetch blog details. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateBlogById = async (id, updatedBlog) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.updateBlogById(id, updatedBlog);

      if (response.status === 200) {
        toast.success("Blog updated successfully!");
      } else if (response.status === 404) {
        toast.error("Blog not found.");
      } else {
        toast.error("Failed to update blog. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteBlogById = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.deleteBlogById(id);

      if (response.status === 204) {
        // Handle successful deletion
      } else if (response.status === 404) {
        toast.error("Blog not found.");
      } else {
        toast.error("Failed to delete blog. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const publishBlog = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.publishBlog(id);

      if (response.status === 200) {
        toast.success("Blog published successfully!");
        // Optionally, you might want to update the blog state or trigger a re-fetch.
        // Example: getAllBlogs();
      } else if (response.status === 404) {
        toast.error("Blog not found.");
      } else {
        toast.error("Failed to publish blog. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const makeBlogDraft = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.makeBlogDraft(id);

      if (response.status === 200) {
        toast.success("Blog set as draft successfully!");
        // Optionally, you might want to update the blog state or trigger a re-fetch.
        // Example: getAllBlogs();
      } else if (response.status === 404) {
        toast.error("Blog not found.");
      } else {
        toast.error("Failed to set blog as draft. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const publishBlogToExternalSites = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.publishBlogToExternalSites(id);

      if (response.status === 200) {
        toast.success("Blog published externally successfully!");
        // Optionally, you might want to update the blog state or trigger a re-fetch.
        // Example: getAllBlogs();
      } else if (response.status === 404) {
        toast.error("Blog not found.");
      } else {
        toast.error(
          "Failed to publish blog externally. Please try again later."
        );
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <BlogContext.Provider
      value={{
        allBlogs,
        isLoading,
        blogDetails,
        getAllBlogs,
        getBlogById,
        updateBlogById,
        deleteBlogById,
        createBlog,
        publishBlog,
        makeBlogDraft,
        publishBlogToExternalSites,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};

export const useBlogContext = () => {
  const context = useContext(BlogContext);
  if (!context) {
    throw new Error("useBlogContext must be used within a BlogProvider");
  }
  return context;
};
